import HttpService from '@/services/http';
import Vue from 'vue';
import {CONTROL_COLORS, CONTROL_COUNT} from "@/configs/endPoints";
import {CONTROLS_TABLE_NAME} from "@/configs/tables/table";

export default {
  actions: {
    /** Счетчик документов на контроле */
    async getControlsCountAction(ctx, data = {}) {
      return await HttpService.post(CONTROL_COUNT, data).then((r) => {
        if ((ctx.state.controlsCount.ids !== null && r.data.ids !== ctx.state.controlsCount.ids) ||
          (ctx.state.controlsCount.ids_new !== null && r.data.ids_new !== ctx.state.controlsCount.ids_new)
        ) {
          ctx.dispatch('refreshDataTable', CONTROLS_TABLE_NAME);
        }
        ctx.commit('controlsCountMutations', r);
      })
    },

    /** Цвета документов на контроле */
    async getControlsColorAction(ctx, data = {}) {
      return await HttpService.post(CONTROL_COLORS, data).then((r) => {
        ctx.commit('controlsColorMutations', r);
      })
    },

    /* Очисть хранилище */
    clearControlsAction(ctx) {
      ctx.commit('clearControlsMutations');
    }
  },
  mutations: {
    controlsCountMutations(state, data) {
      if (data !== undefined && data.data !== undefined) {
        Vue.set(state.controlsCount, 'count', data.data.count);
        Vue.set(state.controlsCount, 'count_new', data.data.count_new);
        Vue.set(state.controlsCount, 'count_expired', data.data.count_expired);
        Vue.set(state.controlsCount, 'ids', data.data.ids);
        Vue.set(state.controlsCount, 'ids_new', data.data.ids_new);
      }
    },
    controlsColorMutations(state, data) {
      if (data !== undefined && data.data !== undefined) {
        data.data.items.forEach((value) => {
          Vue.set(state.controlsColors, value.DOC_ID, value);
        })
      }
    },
    clearControlsMutations(state) {
      state.controlsCount = {
        count: 0,
        count_new: 0,
        count_expired: 0,
        ids: null,
        ids_new: null,
      };
      state.controlsColors = {};
    }
  },
  state: {
    controlsCount: {
      count: 0,
      count_new: 0,
      count_expired: 0,
      ids: null,
      ids_new: null,
    },
    controlsColors: {}
  },
  getters: {
    controlsCountGetter(state) {
      return state.controlsCount
    },
    controlsColorGetter(state) {
      return state.controlsColors
    },
  },
}
