<template>
  <modal-ui ref="modal" title="Настройки таблицы">
    <template #body>
      <combobox-ui
        class="mt-3"
        label="Фиксация столбцов"
        v-model="fix"
        :options="fix_options"
        placeholder="Нет фиксированных"
      ></combobox-ui>

      <div class="d-flex">
        <checkbox-form
          label="Выбрать все / снять выделение"
          class="mt-3 preferences-modal-toggle-checked-all"
          v-model="checked"
        />
      </div>

      <preferences-list class="mt-3" :configs="configs"/>
    </template>

    <template #footer>
      <close-button @click="setDefault">
        <template #icon>
          <menu-table-setting-arrow-cycle-icon/>
        </template>
        <template #title>
          По-умолчанию
        </template>
      </close-button>

      <add-button type="submit" @click="apply">
        <template #icon>
          <check-icon/>
        </template>
        <template #title>
          Применить
        </template>
      </add-button>
    </template>
  </modal-ui>
</template>

<script>
import PreferencesList from '@/components/table/preferencesTable/PreferencesList';
import MenuTableSettingArrowCycleIcon from '@/components/icons/MenuTableSettingArrowCycleIcon';
import CheckIcon from '@/components/icons/CheckIcon.vue';
import VuexAdapter from '@/services/vuexAdapter';
import CheckboxForm from '@/components/form/CheckboxForm';
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';
import CloseButton from '@/components/buttons/CloseButton.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';

export default {
  name: 'PreferencesTableModal',
  components: {
    ComboboxUi,
    AddButton,
    CloseButton,
    ModalUi,
    CheckboxForm,
    PreferencesList,
    MenuTableSettingArrowCycleIcon,
    CheckIcon,
  },
  mixins: [modal],
  props: {
    configs: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    fix: null,
    fix_options: [],
    checked: false,
  }),
  beforeMount() {
    for (let i = 2; i <= 7; i++) {
      this.$set(this.fix_options, i - 1, {
        label: 'Колонка: 1-' + i,
        code: i - 1,
      });
    }
  },
  mounted() {
    this.fix = this.tableFixCols;
  },
  computed: {
    tableFixCols() {
      return this.$store.getters[VuexAdapter.fixColsTableNameGetter(this.configs.name)];
    },
  },
  methods: {
    apply() {
      this.setFixCols();
      this.$emit('apply_table_' + this.configs.name);
      this.hide();
    },
    setDefault() {
      this.fix = null;
      this.setFixCols();
      this.$emit('default_table_' + this.configs.name);
      this.hide();

    },
    on_all() {
      this.$emit('on_all_table_' + this.configs.name);
    },
    off_all() {
      this.$emit('off_all_table_' + this.configs.name);
    },
    setFixCols() {
      this.tableSetFixCols(this.fix);
    },
    tableSetFixCols(data) {
      this.$store.commit(VuexAdapter.fixColsTableNameMutation(this.configs.name), data);
    },
  },
  watch: {
    checked() {
      this.checked ? this.on_all() : this.off_all();
    },
  },
};
</script>
