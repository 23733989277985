import HttpService from '@/services/http';
import {CLIENTS_GET, DOCS_GET, END_POINT_ROW} from "@/configs/endPoints";
import VuexAdapter from "@/services/vuexAdapter";
import Vue from "vue";
import axios from "axios";
import {fullTrim} from "@/services/utilsFunctions";

const mixActions = {
  [DOCS_GET]: (DOC_ID) => {
    return {where: `DOC_ID = ${DOC_ID}`}
  },
  [CLIENTS_GET]: (CLIENT_ID) => {
    return {where: `CLIENT_ID = ${CLIENT_ID}`}
  },
}

const mixMutations = {
  [DOCS_GET]: (data) => {
    data = data.data.items[0] || {};
    if (data['Комментарий'] !== undefined && data['Комментарий']) {
      data['Комментарий'] = fullTrim(data['Комментарий'])
    }

    return data;
  },
}

const actions = {};
const mutations = {};
const states = {};
const getters = {};
const clearMutations = [];

END_POINT_ROW.forEach(endPoint => {
  const actionRowName = VuexAdapter.getNameRowAction(endPoint);

  const actionRowOnlyFetchName = VuexAdapter.getNameRowOnlyFetchAction(endPoint);

  const stateRowLoaderName = VuexAdapter.getLoaderRowGeneralState(endPoint);

  const mutationRowName = VuexAdapter.getNameRowMutation(endPoint);

  const actionAbortRowName = VuexAdapter.getNameAbortRowAction(endPoint);

  const stateRowName = VuexAdapter.getNameRowState(endPoint);

  const mutationClearRowName = VuexAdapter.getNameClearRowMutation(endPoint);

  const getterRowName = VuexAdapter.getNameRowGetter(endPoint);

  const getterLoaderRowName = VuexAdapter.getNameRowLoaderGeneralGetter(endPoint);

  /**
   * Actions get
   * @param ctx
   * @param data
   * @returns {Promise<unknown>}
   */
  actions[actionRowName] = (ctx, data) => {
    data = mixActions[endPoint]
      ? mixActions[endPoint](data)
      : data;

    Vue.set(ctx.state.abortControllersRowState, actionRowName, new AbortController());
    Vue.set(ctx.state, stateRowLoaderName, true);

    return new Promise((resolve, reject) => {

      return HttpService.post(endPoint, data, {
        headers: {'Content-Type': 'text/plain',},
        signal: ctx.state.abortControllersRowState[actionRowName].signal
      }).then((r) => {

        ctx.commit(mutationRowName, r);
        resolve(r);
      }).catch(e => {
        if (!axios.isCancel(e)) {
          reject(e);
        }
      }).finally(() => {
        Vue.set(ctx.state, stateRowLoaderName, false);
      });
    });
  }

  /**
   * Action only fetch
   * @param ctx
   * @param data
   * @returns {Promise<unknown>}
   */
  actions[actionRowOnlyFetchName] = (ctx, data) => {
    data = mixActions[endPoint]
      ? mixActions[endPoint](data)
      : data;

    return new Promise((resolve, reject) => {
      return HttpService.post(endPoint, data).then((r) => {
        if (r.data.items[0]) {
          return resolve(r.data.items[0]);
        }
        reject('Entity not found');
      }).catch(e => {
        reject(e);
      });
    });
  }

  /**
   * Actions abort
   * @param ctx
   */
  actions[actionAbortRowName] = (ctx) => {
    Vue.set(ctx.state, stateRowLoaderName, false);
    if (ctx.state.abortControllersRowState[actionRowName]) {
      ctx.state.abortControllersRowState[actionRowName].abort();
    }
  }

  /**
   * Mutation row
   * @param state
   * @param data
   */
  mutations[mutationRowName] = (state, data) => {
    data = mixMutations[endPoint]
      ? mixMutations[endPoint](data)
      : (data.data.items[0] || {})

    state[stateRowName] = {...data};
  };

  /**
   * Mutation clear
   * @param state
   */
  mutations[mutationClearRowName] = (state) => {
    state[stateRowName] = {};
  };
  clearMutations.push(mutationClearRowName);

  /**
   * State
   * @type {*[]}
   */
  states[stateRowName] = [];

  /**
   * Getter row
   * @param state
   * @returns {*}
   */
  getters[getterRowName] = (state) => {
    return state[stateRowName];
  };

  /**
   * Getter loader
   * @param state
   * @returns {*}
   */
  getters[getterLoaderRowName] = (state) => {
    return state[stateRowLoaderName];
  };

});

export default {
  actions: {
    ...actions,

    /** Очисть все хранилище */
    clearRowAction(ctx) {
      clearMutations.forEach(mutation => {
        ctx.commit(mutation);
      })
      ctx.commit('clearRowMutation');
    },
  },
  mutations: {
    ...mutations,

    clearRowMutation(state) {
      state.abortControllersRowState = {};
    }
  },
  state: {
    abortControllersRowState: {},
    ...states
  },
  getters: {
    ...getters,
  },
}
