<template>
  <div class="additional-info-document-preview">
    <preview-block-header title="Ближайшие документы" :amount="amount" @toggle="toggle"/>

    <transition name="document-sheet">
      <div v-if="showContent" class="additional-info-document-preview--content">
        <uploader-block-client-preview
            class="mt-4"
            :end-point="endPoint"
            :order-by="'DOC_ID DESC'"
            :client-id="clientId"
            :component="() => import('@/components/table/grouped/DocumentsLinks')"
        >
          <template v-slot:list-is-empty>Список контактов пуст</template>
        </uploader-block-client-preview>
      </div>
    </transition>
  </div>
</template>

<script>
import PreviewBlockHeader from "@/components/doc/preview/PreviewBlockHeader.vue";
import UploaderBlockClientPreview from "@/components/clients/UploaderBlockClientPreview";
import {CLIENTS_DOCS_GET} from "@/configs/endPoints";
import clientsPreviewBlock from '@/mixins/clientsPreviewBlock';

export default {
  name: "NearestDocsClientPreview",
  components: {UploaderBlockClientPreview, PreviewBlockHeader},
  props: ['clientId'],
  mixins: [clientsPreviewBlock],
  computed: {
    endPoint() {
      return CLIENTS_DOCS_GET;
    }
  },
}
</script>
