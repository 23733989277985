import HttpService from '@/services/http';
import {AUTH_ORG, AUTH_REFRESH, LOGOUT} from '@/configs/endPoints';
import {IgnorableApiException} from '@/services/exception/ignorableApiException';

export default {
  namespaced: true,
  state: () => ({
    accessToken: null,
    refreshToken: null,
    refreshPromise: null,
    orgIdent: null, // TODO: Не имеет отношение к авторизации. Вынести поле из модуля auth.
  }),
  getters: {
    isAuthorized(state) {
      return !!state.accessToken;
    },
    getOrgIdent(state) {
      return state.orgIdent;
    }
  },
  actions: {
    async login({commit, dispatch}, request) {
      const response = await HttpService.post(AUTH_ORG, request);

      commit('set', response);
      commit('server/set', response, {root: true});
      commit('setOrgIdent', request.org_ident);

      // TODO: Должны ли эти запросы выполнятся здесь?
      // TODO: Что будет, если один из запросов упадет?
      // TODO: Объединить экшены в roles и system модулях
      await Promise.all([
        dispatch('user/fetch', null, {root: true}),
        dispatch('interfaceElementsGetAction', null, {root: true}),
        dispatch('userRolesGetAction', null, {root: true}),
        dispatch('systemServerIdAction', null, {root: true}),
        dispatch('systemAutoSignAction', null, {root: true}),
      ]);
    },
    async refresh({state, commit}) {
      if (state.refreshPromise) {
        return await state.refreshPromise;
      }

      const promise = HttpService.post(AUTH_REFRESH, {refresh_token: state.refreshToken});
      commit('setRefreshPromise', promise);

      try {
        const response = await promise;
        commit('set', response);
        commit('clearRefreshPromise');
      } catch (error) {
        commit('clear');
        throw error;
      }
    },
    async logout({commit}) {
      try {
        await HttpService.post(LOGOUT);
      } catch (error) {
        throw new IgnorableApiException(error);
      } finally {
        commit('clear');
      }
    }
  },
  mutations: {
    set(state, payload) {
      state.accessToken = payload.token;
      state.refreshToken = payload.refresh_token;
    },
    setOrgIdent(state, orgIdent) {
      state.orgIdent = orgIdent;
    },
    clear(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.refreshPromise = null;
      state.orgIdent = null;
    },
    setRefreshPromise(state, payload) {
      state.refreshPromise = payload;
    },
    clearRefreshPromise(state) {
      state.refreshPromise = null;
    }
  }
}
