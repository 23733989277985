<template>
  <button class="btn btn-add-grey" v-bind="$attrs" v-on="$listeners" type="button">
    <plus-icon class="button-icon"/>
    <span v-if="!noTitle">Добавить</span>
  </button>
</template>

<script>
import PlusIcon from "@/components/icons/PlusIcon.vue";

export default {
  name: "AddGrayButton",
  props: {
    noTitle: {
      type: Boolean,
      default: false,
    }
  },
  components: {PlusIcon},
}
</script>
