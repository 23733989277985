<template>
  <div class="position-relative">
    <file-preview-header
        v-if="!hideHeader"
        :file="file"
        :show-pagination="!!pages_init"
        :pages-count="count_pages"
        :current-page="current_slide"
        :show-edit="showEdit && count_pages > 0"
        @toggle="toggle"
        @prev="$refs.carousel.goToPrev()"
        @next="$refs.carousel.goToNext()"
    />

    <transition name="document-sheet">
      <div v-if="showContent">
        <div class="bg-white agile-container">
          <vue-agile
              ref="carousel"
              @after-change="changeSlide"
              v-if="count_pages !== 0 && pages_init === count_pages"
              class="document-file-preview-agile"
              :navButtons="false"
              :dots="dots"
              :infinite="false"
              width-slide-full
          >
            <div v-for="(page_number, key) in pages" v-bind:key="key"
                 class="agile document-file-preview">
              <file-page-preview
                  :page_lazy_load="pages_lazy_load[key]"
                  :file_hash="file['Файл']"
                  :page_number="page_number"
              />
            </div>
          </vue-agile>
          <div v-else-if="fail_meta_get" class="slide load">
            <file-fail-preview @restartLoad="load"/>
          </div>
          <div v-else class="slide load">
            <file-loading-preview/>
          </div>
        </div>
        <div v-if="count_pages >= 15" class="page_num">{{ current_slide }} из {{ count_pages }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {VueAgile} from '@alphadoc/alphadoc-vue-agile';
import FileLoadingPreview from "@/components/doc/files/FileLoadingPreview";
import FileFailPreview from "@/components/doc/files/FileFailPreview";
import FilePagePreview from "@/components/doc/files/FilePagePreview";
import FilePreviewHeader from "@/components/doc/files/FilePreviewHeader";

export default {
  name: 'FilePreview',
  props: [
    'file',
    'hideHeader',
    'showEdit',
  ],
  data: () => ({
    count_pages: 0,
    pages_init: false,
    pages: {},
    pages_lazy_load: {},
    offset_lazy_load: 5,
    timeout_meta_get: 1000,
    timeout_meta_get_limit: 1000 * 60 * 0.5,
    fail_meta_get: false,
    file_preview_page_get_interval: {},
    waiting_request: false,
    not_waiting: false,
    waiting_request_i: 0,
    waiting_request_i_limit: 60,
    showContent: true,
    current_slide: 1,
    dots: true,
    carouselTimeout: null
  }),
  created() {
    this.load();
    this.$on('reload', () => this.load());
  },
  mounted() {
    this.$eventBus.$on('layout-resize', this.reloadVueAgile);
  },
  methods: {
    showDots() {
      if (this.count_pages < 2 || this.count_pages >= 9) {
        this.dots = false;
      }
    },
    changeSlide($event) {
      this.current_slide = $event.currentSlide + 1;

      const offsetNext = this.current_slide + this.offset_lazy_load;
      const offsetBack = this.current_slide - this.offset_lazy_load;

      if (offsetNext <= this.count_pages) {
        for (let i = this.current_slide; i < offsetNext; i++) {
          if (!this.pages_lazy_load[i]) {
            this.$set(this.pages_lazy_load, i, true);
          }
        }
      }

      if (offsetBack > 0) {
        for (let i = this.current_slide; i > offsetBack; i--) {
          if (!this.pages_lazy_load[i]) {
            this.$set(this.pages_lazy_load, i, true);
          }
        }
      }
    },
    toggle(show) {
      this.showContent = show;
    },
    reloadVueAgile() {
      const reload = () => {
        this.$refs?.carousel?.getWidth();
        this.$refs?.carousel?.goTo(this.$refs.carousel.currentSlide, false, false);
      }
      setTimeout(() => reload(100), 100);
      setTimeout(() => reload(200), 200);
      setTimeout(() => reload(400), 400);
      setTimeout(() => reload(800), 800);
      setTimeout(() => reload(1600), 1600);
    },
    ...mapActions(['actionFilePreviewMetaGet', 'actionFileAddPreview']),
    load() {
      this.fail_meta_get = false;

      new Promise((resolve) => {
        let i = 1;

        const f = () => {

          if (this.waiting_request) {
            this.waiting_request_i++;
            if (this.waiting_request_i > this.waiting_request_i_limit) {
              this.fail_meta_get = true;
              this.not_waiting = true;
              this.count_pages = 0;

              if (this?.file_preview_meta_get_interval) {
                clearInterval(this.file_preview_meta_get_interval);
              }

              resolve(this.count_pages);
            }
            return;
          }

          this.waiting_request = true;

          this.actionFilePreviewMetaGet(this.file['Файл']).then(() => {
            if (this.not_waiting) {
              return;
            }
            this.count_pages = this.getterFilePreviewMetaGet;
            if (this.count_pages > 0) {
              if (this?.file_preview_meta_get_interval) {
                clearInterval(this.file_preview_meta_get_interval);
              }

              resolve(this.count_pages);
            }
          }).catch(() => {
            if (this.not_waiting) {
              return;
            }
            if (i === 1) {
              this.actionFileAddPreview([this.file['F_ID']]).catch(() => {
                setTimeout(() => {
                  this.actionFileAddPreview([this.file['F_ID']])
                }, 1000)
              });
            }
            this.count_pages = 0;
            if ((i * this.timeout_meta_get) >= this.timeout_meta_get_limit) {
              this.fail_meta_get = true;
              this.count_pages = 0;
              if (this?.file_preview_meta_get_interval) {
                clearInterval(this.file_preview_meta_get_interval);
              }
            }
            i++;
          }).finally(() => {
            this.waiting_request = false;
          });
        }

        f();

        this.file_preview_meta_get_interval = setInterval(f, this.timeout_meta_get);
      }).then((count_pages) => {
        if (count_pages > 0) {
          for (let i = 1; i <= this.count_pages; i++) {
            this.$set(this.pages, i, i);
            this.$set(this.pages_lazy_load, i, i <= this.offset_lazy_load);
            this.pages_init++;
          }
        }
        this.showDots();
      });
    }
  },
  computed: {
    ...mapGetters(['getterFilePreviewMetaGet']),
  },
  beforeDestroy() {
    if (this.file_preview_meta_get_interval !== undefined) {
      clearInterval(this.file_preview_meta_get_interval);
    }
    this.$off('reload');
    this.$eventBus.$off('layout-resize', this.reloadVueAgile);
  },
  components: {
    FilePreviewHeader,
    VueAgile,
    FileLoadingPreview,
    FileFailPreview,
    FilePagePreview
  }
}
</script>
