import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState('server', ['storageUrl']),
    fileParentTableId() {
      return this.file['PARENT_TABLE_ID'];
    },
    fileParentId() {
      return this.file['PARENT_ID'];
    },
    fileNameType() {
      return this.file['Название'];
    },
    fileName() {
      return this.fileNameType.split('.').slice(0, -1).join('.');
    },
    fileType() {
      return this.fileNameType.split('.').pop().toLowerCase();
    },
    fileRevision() {
      return +this.file['Редакция'];
    },
    fileComment() {
      return this.file['Примечание'];
    },
    fileSign() {
      return this.file['ЭП'];
    },
    fileHash() {
      return this.file['Файл'];
    },
    filePages() {
      return Array
        .from({length: this.pagesCount})
        .fill(null)
        .map((_, index) => (index + 1).toString().padStart(4, '0'))
        .map(page => `${this.storageUrl}${this.fileHash}_page${page}.jpg`);
    },
    fileIcon() {
      switch (this.fileType) {
        case 'doc':
        case 'docx':
        case 'docm':
          return () => import('@/assets/svg/files/doc.svg?component');
        case 'xls':
        case 'xlsm':
        case 'xlsx':
        case 'xltx':
        case 'xlsb':
        case 'xlam':
          return () => import('@/assets/svg/files/xls.svg?component');
        case 'jpg':
        case 'jpeg':
          return () => import('@/assets/svg/files/jpg.svg?component');
        case 'ods':
          return () => import('@/assets/svg/files/ods.svg?component');
        case 'odt':
          return () => import('@/assets/svg/files/odt.svg?component');
        case 'pdf':
          return () => import('@/assets/svg/files/pdf.svg?component');
        case 'png':
          return () => import('@/assets/svg/files/png.svg?component');
        case 'rtf':
          return () => import('@/assets/svg/files/rtf.svg?component');
        default:
          return () => import('@/assets/svg/files/unknown.svg?component');
      }
    },
  }
}
