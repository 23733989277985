<template>
  <dropdown-ui no-overflow close-if-outside-click :close-if-inner-click="isConfig">
    <template #anchor="{toggle}">
      <button class="toggle" @click="toggle">
        <span>{{ data.info[col.keyRow].title }}</span>

        <gear-icon v-if="isConfig"/>
        <dots-icon v-else/>
      </button>

      <preferences-table-modal v-if="isConfig" ref="preferencesModal" :configs="data"/>
    </template>

    <component
      :class="{'filter p-2': !isConfig}"
      :is="componentChild"
      :data="data"
      :col="col.value"
      :key_column="col.keyRow"
      :col_filters="colFilters"
      @show-preferences="showPreferences"
    />
  </dropdown-ui>
</template>

<script>
import Constants from '@/configs/tables/constants';
import GearIcon from '@/assets/svg/gear.svg?component';
import DotsIcon from '@/assets/svg/dots-vertical.svg?component';
import VuexAdapter from '@/services/vuexAdapter';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import PreferencesTableModal from '@/components/modals/Table/PreferencesTableModal.vue';

export default {
  name: 'TableHeadDropdown',
  props: [
    'data',
    'col',
  ],
  components: {
    Date: () => import('@/components/table/filters/DateFilter'),
    Flag: () => import('@/components/table/filters/FlagFilter'),
    Integer: () => import('@/components/table/filters/IntegerFilter'),
    Identifier: () => import('@/components/table/filters/IdentifierFilter'),
    String: () => import('@/components/table/filters/StringFilter'),
    Eye: () => import('@/components/table/filters/EyeFilter'),
    AsyncIdentifier: () => import('@/components/table/filters/AsyncIdentifier'),
    GearIcon,
    DotsIcon,
    DropdownUi,
    PreferencesTableModal,
  },
  data: () => ({
    identifier: Constants.identifier,
    asyncIdentifier: Constants.asyncIdentifier,
  }),
  computed: {
    isConfig() {
      return !!this.data.info[this.col.keyRow].configTitle;
    },
    tableFilterData() {
      return this.$store.getters[VuexAdapter.filterTableNameGetter(this.data.name)];
    },
    componentChild() {
      return this.data.info[this.col.keyRow].configTitle !== undefined && this.data.info[this.col.keyRow].configTitle ? 'Eye' : this.data.info[this.col.keyRow].type;
    },
    colFilters() {
      return this.tableFilterData[this.col.keyRow];
    },
  },
  methods: {
    showPreferences() {
      this.$refs.preferencesModal.show();
    },
  }
}
</script>

<style scoped lang="scss">
.toggle {
  width: 100%;
  height: 24px;
  padding: 1px 5px 1px 1px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 500;
  letter-spacing: 0.01rem;
}

.filter {
  width: 250px;
  font-weight: var(--font-weight); // TODO: Избавиться от перекрытия стилей
}
</style>
