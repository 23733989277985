<template>
  <div class="additional-info-document-preview">
    <preview-block-header title="Дополнительная информация" @toggle="toggle"/>

    <transition name="document-sheet">
      <div v-if="showContent" class="additional-info-document-preview--content">
        <tabs-ui>
          <tab-ui v-if="showClientsTab" active>
            <template #title>
              Контрагенты
              <badge-ui class="ml-1" :value="docContractorsCountGetter"/>
            </template>
            <uploader-block-document-preview class="mt-4"
                                             :end-point="DOCS_CLIENTS_GET"
                                             :doc-id="doc_id"
                                             :component="() => import('@/components/doc/contractors/ContractorRow')">
              <template v-slot:list-is-empty>Список контрагентов пуст</template>
            </uploader-block-document-preview>
          </tab-ui>
          <tab-ui v-if="showDocumentsViewTab" :active="!showClientsTab">
            <template #title>
              Просмотры
              <badge-ui class="ml-1" :value="docViewsCountGetter"/>
            </template>
            <uploader-block-document-preview class="mt-4"
                                             :end-point="DOCS_VIEWS_ALL"
                                             :doc-id="doc_id"
                                             :component="() => import('@/components/doc/preview/rows/ViewRowDocumentPreview')">
              <template v-slot:list-is-empty>Список просмотров пуст</template>
            </uploader-block-document-preview>
          </tab-ui>
          <tab-ui :active="!showClientsTab && !showDocumentsViewTab">
            <template #title>
              Документы
              <badge-ui class="ml-1" :value="documentsLinksCountGetter"/>
            </template>
            <uploader-block-document-preview class="mt-4"
                                             :end-point="DOC_LINKS_GET"
                                             :doc-id="doc_id"
                                             :component="() => import('@/components/table/grouped/DocumentsLinks')">
              <template v-slot:list-is-empty>Список связанных документов пуст</template>
            </uploader-block-document-preview>
          </tab-ui>
        </tabs-ui>
      </div>
    </transition>
  </div>
</template>

<script>
import PreviewBlockHeader from "@/components/doc/preview/PreviewBlockHeader.vue";
import {mapGetters} from "vuex";
import UploaderBlockDocumentPreview from "@/components/doc/preview/UploaderBlockDocumentPreview";
import BadgeUi from "@/components/ui/BadgeUi.vue";
import VuexAdapter from "@/services/vuexAdapter";
import {DOC_LINKS_GET, DOCS_CLIENTS_GET, DOCS_VIEWS_ALL} from "@/configs/endPoints";
import {COUNTERPARTIES_REGIONS_SEGMENTS__VIEW, DOCUMENTS__VIEWS} from "@/configs/events";
import TabsUi from "@/components/ui/TabsUi";
import TabUi from "@/components/ui/TabUi";

export default {
  name: "AdditionalInfoDocumentPreview",
  props: ['doc_id'],
  components: {TabsUi, TabUi, BadgeUi, UploaderBlockDocumentPreview, PreviewBlockHeader},
  data() {
    return {
      showContent: true,
      DOCS_CLIENTS_GET,
      DOCS_VIEWS_ALL,
      DOC_LINKS_GET
    };
  },
  computed: {
    ...mapGetters(['accessToEvent']),
    ...mapGetters({
      docViewsCountGetter: VuexAdapter.getNameCountGetter(DOCS_VIEWS_ALL),
      documentsLinksCountGetter: VuexAdapter.getNameCountGetter(DOC_LINKS_GET),
      docContractorsCountGetter: VuexAdapter.getNameCountGetter(DOCS_CLIENTS_GET),
    }),
    showDocumentsViewTab() {
      return this.accessToEvent(DOCUMENTS__VIEWS);
    },
    showClientsTab() {
      return this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW);
    }
  },
  methods: {
    toggle(show) {
      this.showContent = show;
    },
  }
}
</script>
