import HttpService from '@/services/http';
import {SYSTEM_AUTO_SIGN_STATUS, SYSTEM_SERVER_ID} from '@/configs/endPoints';

export default {
  actions: {
    /* ID сервера */
    async systemServerIdAction(ctx) {
      return await HttpService.post(SYSTEM_SERVER_ID).then((r) => {
        ctx.commit('systemServerIdMutation', r);
      });
    },
    /* Статус системы автоподписи */
    async systemAutoSignAction(ctx) {
      return await HttpService.post(SYSTEM_AUTO_SIGN_STATUS).then((r) => {
        ctx.commit('systemAutoSignMutation', Boolean(r.data.status));
      });
    },
  },
  mutations: {
    clearSystemMutation(state) {
      state.systemServerId = null;
      state.systemAutoSignEnable = false;
    },
    systemServerIdMutation(state, data) {
      state.systemServerId = data.data.server_id;
    },
    systemAutoSignMutation(state, bool) {
      state.systemAutoSignEnable = bool;
    },
    systemIsCollapsedMutation(state, bool) {
      state.systemIsCollapsed = bool;
    },
    systemIsCollapsedMinimizerMutation(state, bool) {
      state.systemIsCollapsedMinimizer = bool;
    },
  },
  state: {
    systemServerId: null,
    systemAutoSignEnable: false,
    systemIsCollapsed: false,
    systemIsCollapsedMinimizer: false,
  },
  getters: {
    getSystemServerIdGetter(state) {
      return state.systemServerId;
    },
    hasSystemServerIdGetter(state) {
      return state.systemServerId !== null;
    },
    systemIsCollapsedGetter(state) {
      return state.systemIsCollapsed;
    },
    systemIsCollapsedMinimizerGetter(state) {
      return state.systemIsCollapsedMinimizer;
    },
    AutoSignEnableGetter(state) {
      return state.systemAutoSignEnable;
    },
  },
}
