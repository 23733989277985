<template>
  <modal-ui ref="modal" title="Внимание" size="s" popup @close="$emit('cancel')">
    <template #body>
      <p class="text">
        <strong>Файл <span class="file-name">«{{ fileName }}»</span> - изменен</strong>
      </p>
      <p class="text">Хотите сохранить изменения и добавить его в документ с новой редакцией?</p>
    </template>

    <template #footer>
      <close-button @click="$emit('cancel')"/>

      <discard-button @click="$emit('discard')">
        <template #title>
          <span class="button-title">Hе сохранять</span>
          <span class="button-title-small">Hет</span>
        </template>
      </discard-button>

      <save-button @click="$emit('save')">
        <template #title>
          <span class="button-title">Сохранить</span>
          <span class="button-title-small">Да</span>
        </template>
        <template #icon>
          <check-icon/>
        </template>
      </save-button>
    </template>
  </modal-ui>
</template>

<script>
import {defineComponent} from 'vue';
import CloseButton from '@/components/buttons/CloseButton.vue';
import SaveButton from '@/components/buttons/SaveButton.vue';
import CheckIcon from '@/components/icons/CheckIcon.vue';
import DiscardButton from '@/components/buttons/DiscardButton.vue';
import ModalUi from '@/components/ui/ModalUi.vue';

export default defineComponent({
  name: 'EditFileConfirmModal',
  components: {ModalUi, DiscardButton, CheckIcon, SaveButton, CloseButton},
  props: {
    fileName: {
      type: String,
      required: true,
    }
  },
  emits: ['cancel', 'discard', 'save', 'close'],
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/scss/resolutions";

.text {
  /* TODO: Отказаться от перекрытия стилей */
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.file-name {
  word-break: break-word;
}

.button-title {
  @media #{$tiny-and-less} {
    display: none;
  }
}

.button-title-small {
  display: none;

  @media #{$tiny-and-less} {
    display: inline;
  }
}
</style>
