<template>
  <div class="additional-info-document-preview">
    <add-address-modal ref="addModal" :parent-id="clientId" :parent-table-id="parentTableId"/>

    <preview-block-header title="Адреса" :amount="amount" :show-add="showAdd" @toggle="toggle" @add="add"/>

    <transition name="document-sheet">
      <div v-if="showContent" class="additional-info-document-preview--content">
        <uploader-block-client-preview
            class="mt-4"
            :end-point="endPoint"
            :client-id="clientId"
            :component="() => import('@/components/clients/rows/AddressRow')"
        >
          <template v-slot:list-is-empty>Список адресов пуст</template>
        </uploader-block-client-preview>
      </div>
    </transition>
  </div>
</template>

<script>
import UploaderBlockClientPreview from "@/components/clients/UploaderBlockClientPreview";
import {CLIENTS_ADDRESSES_GET} from "@/configs/endPoints";
import AddAddressModal from "@/components/modals/Address/AddAddressModal.vue";
import clientsPreviewBlock from '@/mixins/clientsPreviewBlock';
import PreviewBlockHeader from '@/components/doc/preview/PreviewBlockHeader.vue';
import {CLIENTS_TABLE} from '@/configs/dbTables';

export default {
  name: "AddressesClientPreview",
  components: {PreviewBlockHeader, AddAddressModal, UploaderBlockClientPreview},
  props: ['clientId'],
  mixins: [clientsPreviewBlock],
  computed: {
    parentTableId() {
      return CLIENTS_TABLE;
    },
    endPoint() {
      return CLIENTS_ADDRESSES_GET;
    }
  }
}
</script>
