<template>
  <div class="master-modal-container__menu--items">
    <div v-for="(step, index) in steps"
         :key="step.title"
         class="master-modal-container__menu--item"
         :class="stepClasses(step, index)"
         @click="select(step, index)"
    >{{ step.title }}</div>
  </div>
</template>

<script>
export default {
  name: "ItemsStepMaster",
  model: {
    prop: 'value',
    event: 'change',
  },
  data: () => ({
    valueProxy: null,
  }),
  props: {
    value: {
      type: Object,
    },
    formData: {
      type: Object,
    },
    steps: {
      type: Array,
      required: true
    },
    cursorStep: {
      type: Number,
      required: true
    },
    disabledMotion: {
      type: Boolean,
      required: false
    },
  },
  created() {
    this.valueProxy = this.value;
  },
  computed: {
    disabled() {
      return (step, index) => index > this.cursorStep || this.disabledMotion || (step?.disabled && step?.disabled(this))
    },
    stepClasses() {
      return (step, index) => ({
        active: step?.title === this.valueProxy?.title,
        disabled: this.disabled(step, index),
      })
    },
  },
  methods: {
    select(step, index) {
      if (this.disabled(step, index)) {
        return;
      }
      this.valueProxy = step;
      this.$emit('change', this.valueProxy);
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal?.title !== this.valueProxy?.title) {
          this.valueProxy = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>