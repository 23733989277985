<template>
  <modal-ui ref="modal" title="Добавление контакта" :error-message="errorMessage">
    <template #body>
      <form-builder ref="formBuilder" v-model="form" :fields="contactForm"/>
    </template>

    <template #footer>
      <close-button @click="hide"/>
      <add-button @click="apply"/>
    </template>
  </modal-ui>
</template>

<script>
import CloseButton from "@/components/buttons/CloseButton";
import AddButton from "@/components/buttons/AddButton";
import {mapActions, mapGetters} from "vuex";
import VuexAdapter from "@/services/vuexAdapter";
import {CLIENTS_CONTACTS_GET, CONTACT_ADD, CONTACT_TYPES_GET} from "@/configs/endPoints";
import FormBuilder from "@/components/form/FormBuilder";
import contactForm from "@/configs/forms/clients/contact";
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';

export default {
  name: "AddContactModal",
  components: {ModalUi, FormBuilder, AddButton, CloseButton},
  mixins: [modal],
  props: {
    parent_id: {
      type: Number,
      required: true
    },
    parent_table_id: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      contactForm: contactForm(this),
      errorMessage: null,
      form: {
        contact_id: null,
        parent_table_id: this.parent_table_id,
        parent_id: this.parent_id,
        contact_type: null,
        contact: null,
        comment: '',
        mask: '',
      }
    }
  },
  created() {
    this.clientContactTypesAction();
  },
  computed: {
    ...mapGetters({
      clientContactTypesSelectGetter: VuexAdapter.getNameSelectGetter(CONTACT_TYPES_GET),
    })
  },
  methods: {
    ...mapActions({
      refreshPartlyPreviewAction: 'refreshPartlyPreviewAction',
      clientContactTypesAction: VuexAdapter.getNameAction(CONTACT_TYPES_GET),
      addClientContactsAction: VuexAdapter.getNameAction(CONTACT_ADD),
    }),
    apply() {
      if (!this.$refs.formBuilder.validate()) {
        return;
      }

      this.errorMessage = null;
      this.showLoader();

      const form = {
        ...this.form,
        contact_type: this.form.contact_type.label
      };

      this.addClientContactsAction(form).then(() => {
        this.refreshPartlyPreviewAction(CLIENTS_CONTACTS_GET)
        this.hide();
      }).catch(e => {
        this.errorMessage = e.error_message || 'Неизвестная ошибка';
        this.hideLoader();
      });
    },
  }
}
</script>
