import { render, staticRenderFns } from "./FilesDocumentPreview.vue?vue&type=template&id=65116b48&scoped=true"
import script from "./FilesDocumentPreview.vue?vue&type=script&lang=js"
export * from "./FilesDocumentPreview.vue?vue&type=script&lang=js"
import style0 from "./FilesDocumentPreview.vue?vue&type=style&index=0&id=65116b48&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65116b48",
  null
  
)

export default component.exports