<template>
  <div class="loader-ui">
    <div class="spinner">
      <spinner-ui v-if="!hiddenSpinner" size="l"></spinner-ui>
    </div>

    <span v-if="text" class="text">{{ text }}</span>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';

export default defineComponent({
  name: 'LoaderUi',
  components: {SpinnerUi},
  props: {
    hiddenSpinner: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors";

.loader-ui {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;

  background-color: $loader-background;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.text {
  color: $font-muted-color;
  text-align: center;
}
</style>
