<template>
  <div>
    <div v-for="row in rows" :key.prop="row">
      <component class="mt-3" :row="row" :is="component"/>
    </div>
    <div v-if="!rows.length && !error" class="grey-text mt-3">
      <slot name="list-is-empty">Список пуст</slot>
    </div>
    <div v-if="error" class="grey-text mt-3">
      <slot name="list-is-empty">Ошибка получения данных</slot>
    </div>
    <div class="uploader-block-footer">
      <div v-if="loader"
           class="progress-bar progress-bar-striped progress-bar-animated bg-warning w-100"
           aria-valuemin="0" aria-valuemax="100" style="height: 5px"
           role="progressbar"></div>
      <show-more-button v-if="showMoreButton"
                        v-on:click="load"/>
    </div>
  </div>
</template>

<script>
import ShowMoreButton from "@/components/buttons/ShowMoreButton.vue";
import {ERROR_NOTIFY_TYPE} from "@/configs/notifyTypes";
import {ErrorHelper} from '@/services/errorHelper';

export default {
  name: "UploaderBlockDocumentPreview",
  components: {ShowMoreButton},
  data: () => ({
    limit: 5
  }),
  props: [
    'endPoint',
    'component',
    'docId',
  ],
  methods: {
    async load() {
      await this.$store.dispatch(this.actionLoader, {
        doc_id: this.docId,
        limit: this.limit,
        offset: this.$store.getters[this.getter].length,
      })
    },
  },
  computed: {
    showMoreButton() {
      return !this.loader && this.$store.getters[this.getter].length < this.$store.getters[this.getterCount];
    },
    rows() {
      return this.$store.getters[this.getter];
    },
    error() {
      return this.$store.getters[this.getterError];
    },
    loader() {
      return this.$store.getters[this.$vuexAdapter.getNameLoadingGetter(this.endPoint)];
    },
    actionLoader() {
      return this.$vuexAdapter.getNameLoadingAction(this.endPoint);
    },
    getter() {
      return this.$vuexAdapter.getNameGetter(this.endPoint);
    },
    getterCount() {
      return this.$vuexAdapter.getNameCountGetter(this.endPoint);
    },
    getterError() {
      return this.$vuexAdapter.getNameErrorGetter(this.endPoint);
    },
  },
  watch: {
    error(val) {
      if (val) {
        this.$notify({
          title: 'Ошибка получения данных',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(val) || 'Неизвестная ошибка', // TODO: 'Неизвестная ошибка' никогда не отобразится
        });
      }
    },
  }
}
</script>
