<template>
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 11C11 8.23858 13.2386 6 16 6H28V13.25C28 16.1495 30.3505 18.5 33.25 18.5H41C41.1726 18.5 41.3402 18.4781 41.5 18.437V41.25C41.5 44.0114 39.2614 46.25 36.5 46.25H16C13.2386 46.25 11 44.0114 11 41.25V11ZM40.4842 14.5L32 7.74936V13.25C32 13.9404 32.5596 14.5 33.25 14.5H40.4842ZM16 2C11.0294 2 7 6.02944 7 11V41.25C7 46.2206 11.0294 50.25 16 50.25H36.5C41.4706 50.25 45.5 46.2206 45.5 41.25V15.3083C45.5 14.0894 44.9443 12.9371 43.9905 12.1782L32.2919 2.86993C31.584 2.30666 30.7061 2 29.8014 2H16Z" fill="#DADFF1"/>
  </svg>
</template>

<script>
export default {
  name: "DefaultFormFileIcon"
}
</script>

<style scoped>

</style>