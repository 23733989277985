<template>
  <div class="additional-info-document-preview">
    <add-contact-modal ref="addModal" :parent_id="clientId" :parent_table_id="parentTableId"/>

    <preview-block-header title="Контакты" :amount="amount" :show-add="showAdd" @toggle="toggle" @add="add"/>

    <transition name="document-sheet">
      <div v-if="showContent" class="additional-info-document-preview--content">
        <uploader-block-client-preview
            class="mt-4"
            :end-point="endPoint"
            :client-id="clientId"
            :component="() => import('@/components/clients/rows/ContactRow')"
        >
          <template v-slot:list-is-empty>Список контактов пуст</template>
        </uploader-block-client-preview>
      </div>
    </transition>
  </div>
</template>

<script>
import UploaderBlockClientPreview from "@/components/clients/UploaderBlockClientPreview";
import PreviewBlockHeader from "@/components/doc/preview/PreviewBlockHeader.vue";
import {CLIENTS_CONTACTS_GET} from "@/configs/endPoints";
import AddContactModal from "@/components/modals/Contact/AddContactModal";
import clientsPreviewBlock from '@/mixins/clientsPreviewBlock';
import {CLIENTS_TABLE} from '@/configs/dbTables';

export default {
  name: "ContactsClientPreview",
  components: {AddContactModal, UploaderBlockClientPreview, PreviewBlockHeader},
  props: ['clientId'],
  mixins: [clientsPreviewBlock],
  computed: {
    parentTableId() {
      return CLIENTS_TABLE;
    },
    endPoint() {
      return CLIENTS_CONTACTS_GET;
    }
  },
}
</script>
