<template>
  <field-ui class="combobox-ui" :id="id" :label="label" :required="required" :error="errorMessage" :hint="hint">
    <dropdown-ui
      ref="dropdown"
      :offset="4"
      shrink
      full-width
      close-if-outside-click
      @open="$emit('focus')"
      @close="$emit('blur')"
    >
      <template #anchor="{isOpen}">
        <div class="field" :class="[{_open: isOpen}, className]" @click="toggle">
          <div class="content">
            <slot name="prefix"/>

            <span v-if="showPlaceholder" class="value value_placeholder">{{ placeholder }}</span>

            <chip-list-ui v-else-if="multiple" :options="modelValue" @close.stop="onChipClose"></chip-list-ui>

            <span v-else class="value">{{ modelValue.label }}</span>

            <slot name="postfix"/>

            <button-icon-ui v-if="!noClear && modelValue" title="Нажмите чтобы очистить" @click.stop="clear">
              <close-icon></close-icon>
            </button-icon-ui>

            <button-icon-ui v-rotate="isOpen">
              <chevron-icon></chevron-icon>
            </button-icon-ui>
          </div>
        </div>
      </template>

      <div class="body">
        <div v-if="!noSearch && options.length > 7" class="search">
          <input class="search-input" ref="search" v-focus v-model.trim="input" placeholder="Начните вводить">
          <search-icon class="search-icon"></search-icon>
        </div>

        <div v-if="!markedFilteredWithCustomOptions.length" class="empty">
          Ничего не найдено
        </div>

        <dropdown-list-ui
          v-else
          ref="list"
          :options="markedFilteredWithCustomOptions"
          @select="onSelect"
        ></dropdown-list-ui>
      </div>

      <collapse-button @click="$refs.dropdown.hide()"></collapse-button>
    </dropdown-ui>
  </field-ui>
</template>

<script>
import {defineComponent} from 'vue';
import ChevronIcon from '@/assets/svg/chevron.svg?component';
import SearchIcon from '@/assets/svg/search.svg?component';
import CloseIcon from '@/assets/svg/close.svg?component';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import Focus from '@/directives/focus';
import {uniqueId} from 'lodash-es';
import Rotate from '@/directives/rotate';
import ChipListUi from '@/components/ui/ChipListUi.vue';
import {keys, only} from '@/utils/propsValidators';
import FieldUi from '@/components/ui/FieldUi.vue';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import field from '@/mixins/form/field';
import CollapseButton from '@/components/buttons/CollapseButton.vue';
import DropdownListUi from '@/components/ui/DropdownListUi.vue';

const CUSTOM_OPTION_CODE = Symbol('combobox-custom-option-code');

export default defineComponent({
  name: 'ComboboxUi',
  components: {
    DropdownListUi,
    CollapseButton,
    ButtonIconUi,
    FieldUi,
    ChipListUi,
    DropdownUi,
    ChevronIcon,
    SearchIcon,
    CloseIcon,
  },
  directives: {
    Focus,
    Rotate,
  },
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: {
      type: [Object, Array],
      validator: keys('label', 'code'),
    },
    color: {
      type: String,
      default: 'gray',
      validator: only('gray', 'white'),
    },
    size: {
      type: String,
      default: 'm',
      validator: only('m', 'l'),
    },
    options: {
      type: Array,
      default: () => [],
      validator: keys('label', 'code'),
    },
    placeholder: {
      type: String,
      default: 'Выберите значение',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noClear: {
      type: Boolean,
      default: false,
    },
    noSearch: {
      type: Boolean,
      default: false,
    },
    withCustomOption: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value', 'focus', 'blur'],
  mixins: [field],
  data() {
    return {
      id: uniqueId('combobox-ui-'),
      input: '',
    };
  },
  computed: {
    className() {
      return [{
        _prefix: !!this.$slots.prefix,
        _postfix: !!this.$slots.postfix,
        _disabled: this.disabled || !this.options.length,
        _error: !!this.error,
        _multiple: this.multiple,
      },
        `_size-${this.size}`,
        `_color-${this.color}`,
      ];
    },
    showPlaceholder() {
      return this.multiple ? !this.modelValue.length : !this.modelValue;
    },
    filteredOptions() {
      return this.options.filter(option => this.normalize(option.label).includes(this.normalize(this.input)));
    },
    markedFilteredOptions() {
      return this.filteredOptions.map(option => ({
        ...option,
        checked: this.isChecked(option),
      }));
    },
    markedFilteredWithCustomOptions() {
      if (
        !this.withCustomOption ||
        !this.input ||
        this.markedFilteredOptions.some(option => option.label === this.input)
      ) {
        return this.markedFilteredOptions;
      }

      return [{
        label: this.input,
        code: CUSTOM_OPTION_CODE,
      },
        ...this.markedFilteredOptions,
      ];
    },
  },
  watch: {
    input() {
      this.$refs.list?.setActive(null);
    },
  },
  methods: {
    onSelect(option) {
      if (!this.multiple) {
        this.select(option);
        this.$refs.dropdown.hide();
        return;
      }

      if (option.checked) {
        this.remove(option);
      } else {
        this.add(option);
      }
    },
    onChipClose(_, option) {
      this.remove(option);
    },
    clear() {
      this.$emit('update:model-value', this.multiple ? [] : null);
    },
    toggle() {
      this.$refs.dropdown.toggle();
      this.input = '';
    },
    normalize(value) {
      return value.toLowerCase().replaceAll('ё', 'е');
    },
    select(option) {
      this.$emit('update:model-value', option);
    },
    add(option) {
      const newOptions = [...this.modelValue, {label: option.label, code: option.code}];
      this.$emit('update:model-value', newOptions);
    },
    remove(option) {
      const newOptions = this.modelValue.filter(checkedOption => checkedOption.code !== option.code);
      this.$emit('update:model-value', newOptions);
    },
    isChecked(option) {
      if (this.multiple) {
        return this.modelValue.some(checkedOption => checkedOption.code === option.code);
      } else {
        return this.modelValue?.code === option.code;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.field {
  display: flex;

  border-radius: 8px;
  cursor: pointer;

  transition: box-shadow var(--transition-fast);

  &._size-m {
    min-height: 36px;
    padding: 0 8px 0 12px;

    &._prefix {
      padding-left: 8px;
    }
  }

  &._size-l {
    min-height: 48px;
    padding: 0 12px 0 16px;

    &._prefix {
      padding-left: 12px;
    }

    font-size: var(--font-size-xl);
    line-height: var(--font-size-xl);
  }

  &._color-gray {
    background-color: var(--color-gray-075);
  }

  &._color-white {
    box-shadow: var(--shadow);
    background-color: var(--color-white);

    &._open {
      box-shadow: var(--shadow-control);
    }
  }

  &._prefix {
    padding-left: 8px;

    .value,
    .chip-list-ui {
      padding-left: 4px;
    }
  }

  &._open {
    box-shadow: var(--shadow-control);
  }

  &._disabled {
    pointer-events: none;

    .content {
      opacity: 0.5;
    }
  }

  &._error {
    box-shadow: var(--shadow-control-error);
  }

  &._multiple {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.content {
  flex-grow: 1;
  min-width: 0;

  display: flex;
  align-items: center;

  > :deep(*) {
    flex-shrink: 0;
  }
}

.value {
  flex: 1 1 auto;
  padding-right: 4px;

  /* TODO: Избавиться от перекрытия стилей */
  color: var(--color-gray-1000);
  font-weight: var(--font-weight);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &_placeholder {
    color: var(--color-gray-500);
  }
}

.chip-list-ui {
  flex: 1 1 auto;
  padding-right: 4px;

  max-height: 180px;
  overflow-y: auto;
}

.search {
  position: relative;
}

.search-input {
  width: 100%;
  height: 36px;

  padding-left: 12px;
  padding-right: 36px;

  color: var(--color-gray-600);

  &::placeholder {
    color: var(--color-gray-500);
  }
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 12px;

  transform: translateY(-50%);

  pointer-events: none;
}

.empty {
  padding: 16px;
  text-align: center;
}

.body {
  overflow: hidden;

  display: flex;
  flex-direction: column;

  box-shadow: var(--shadow);
  border-radius: 8px;
}

.collapse-button {
  flex-shrink: 0;
}
</style>
