import typeDocAndProjectStepMaster from "@/configs/master/steps/typeDocAndProjectStepMaster";
import contentDocStepMaster from "@/configs/master/steps/contentDocStepMaster";
import settingDeadlineStepMaster from "@/configs/master/steps/settingDeadlineStepMaster";
import selectDocRoute from "@/configs/master/steps/selectDocRouteStepMaster";
import assignBarcodeStepMaster from "@/configs/master/steps/assignBarcodeStepMaster";
import addResolutionStepMaster from "@/configs/master/steps/addResolutionStepMaster";
import attachFilesStepMaster from "@/configs/master/steps/attachFilesStepMaster";
import linkDocumentStepMaster from "@/configs/master/steps/linkDocumentStepMaster";
import documentRegistrationStepMaster from "@/configs/master/steps/documentRegistrationStepMaster";
import addCounterpartyLinksStepMaster from "@/configs/master/steps/addCounterpartyLinksStepMaster";
import performanceStepMaster from "@/configs/master/steps/performanceStepMaster";
import startParametersStepMaster from "@/configs/master/steps/startParametersStepMaster";
import {STANDARD_ROUTE} from "@/configs/routeTypes";
import {LOADING_MASTER_STEP_STATUS} from "@/configs/master/masterStepLoadingStatus";
import {START_NOW_CONTROL_TYPE} from "@/configs/controlTypes";
import {CALENDAR_DAYS_TERM_FROM_MASTER, NONE_TERM_FROM_MASTER, WORKING_DAYS_TERM_FROM_MASTER, DATE_TERM_FROM_MASTER} from "@/configs/master/termFromMaster";
import {AT_ONCE_START_CONTROL_TYPE} from "@/configs/master/startControlTypeMaster";
import {DOCUMENT_DOC_TYPE} from "@/configs/docTypes";

export default {
    title: 'Создание документа',
    attentionMessageBeforeClose: 'Вы собираетесь остановить процесс <br>создания документа.<br> Данная операция не обратима!<br> Продолжить?',
    steps: (Vue) => {
        let steps = [
            typeDocAndProjectStepMaster,
            contentDocStepMaster,
            settingDeadlineStepMaster,
            assignBarcodeStepMaster,
            addResolutionStepMaster,
            attachFilesStepMaster,
            linkDocumentStepMaster,
            addCounterpartyLinksStepMaster,
            selectDocRoute,
        ];

        Vue.formData?.routes_responsible_unspecified_steps?.forEach((item, number) => {
            const title = `Выбор исполнителей ${number + 1}/${Vue.formData.routes_responsible_unspecified_steps.length}`;
            steps.push({
                title,
                titleModal: title,
                component: () => import('@/components/master/step/RoutesResponsibleUnspecifiedStepMaster'),
                disabled: (Vue) => Vue.formData?.created,
                additional: {
                   item, number
                }
            });
        });

        steps = [
            ...steps,
            documentRegistrationStepMaster,
            performanceStepMaster,
            startParametersStepMaster,
        ];

        return [...steps];
    },
    formData: {
        termFrom: NONE_TERM_FROM_MASTER,
        skip_barcode: true,
        controller_id: null,
        step_attach_files: false,
        step_link_document: false,
        step_assign_barcode: false,
        step_add_counterparty_links: false,
        step_add_resolution: false,
        created: false,
        clear_control_start: false,
        route_none: false,
        errors: false,
        resolution: null,
        dt_id: DOCUMENT_DOC_TYPE,
        p_id: null,
        mask: null,
        mask_data: null,
        mask_data_values: {},
        mask_form_data: {},
        user_files: [],
        route_copy_doc_id: null,
        files: [],
        clients: [],
        documents: [],
        routes_responsible_unspecified_steps: [],
        type_route: STANDARD_ROUTE,
        control_type: START_NOW_CONTROL_TYPE,
        start_control_type: AT_ONCE_START_CONTROL_TYPE,
        [WORKING_DAYS_TERM_FROM_MASTER]: null,
        [WORKING_DAYS_TERM_FROM_MASTER + '_VIEW']: null,
        [CALENDAR_DAYS_TERM_FROM_MASTER]: null,
        [CALENDAR_DAYS_TERM_FROM_MASTER + '_VIEW']: null,
        [DATE_TERM_FROM_MASTER]: null,
        loadingStatuses: {
            doc: LOADING_MASTER_STEP_STATUS,
            links: LOADING_MASTER_STEP_STATUS,
            temps: LOADING_MASTER_STEP_STATUS,
            resolution: LOADING_MASTER_STEP_STATUS,
            files: LOADING_MASTER_STEP_STATUS,
            routes: LOADING_MASTER_STEP_STATUS,
            copy_route: LOADING_MASTER_STEP_STATUS,
        }
    },
}