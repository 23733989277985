<template>
    <div class="sign--table--body">
      <row-table-sign v-for="file in filesForSignGetter"
                      :key="file.file_id"
                      :file="file"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import RowTableSign from "@/components/sign/RowTableSign";

export default {
  name: "BodyTableSign",
  components: {RowTableSign},
  computed: {
    ...mapGetters(['filesForSignGetter'])
  },
}
</script>