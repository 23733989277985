<template>
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9772 2.16602L30.3333 2.16602C30.908 2.16602 31.4591 2.39429 31.8654 2.80062L44.8654 15.8006C45.2717 16.2069 45.5 16.758 45.5 17.3327V27.2994C45.5 28.496 44.5299 29.466 43.3333 29.466C42.1367 29.466 41.1667 28.496 41.1667 27.2994V18.2301L29.4359 6.49935H19.0667C17.2107 6.49935 15.9491 6.50104 14.9739 6.58071C14.024 6.65832 13.5382 6.799 13.1994 6.97166C12.384 7.38711 11.7211 8.05002 11.3056 8.86539C11.133 9.20426 10.9923 9.69003 10.9147 10.6399C10.835 11.6151 10.8333 12.8768 10.8333 14.7327V25.9994C10.8333 27.196 9.86329 28.166 8.66667 28.166C7.47005 28.166 6.5 27.196 6.5 25.9994L6.5 14.6432C6.49997 12.8991 6.49995 11.4597 6.59575 10.2871C6.69526 9.06918 6.90882 7.94966 7.44461 6.8981C8.27551 5.26736 9.60135 3.94153 11.2321 3.11063C12.2836 2.57483 13.4032 2.36128 14.621 2.26177C15.7936 2.16597 17.2331 2.16599 18.9772 2.16602Z" fill="#DADFF1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.3332 2.75C31.5298 2.75 32.4998 3.72005 32.4998 4.91667V13.8662C32.4998 14.5086 32.5015 14.8602 32.5222 15.1128C32.523 15.1229 32.5238 15.1324 32.5247 15.1414C32.5336 15.1422 32.5432 15.143 32.5532 15.1438C32.8058 15.1645 33.1574 15.1662 33.7998 15.1662H42.7494C43.946 15.1662 44.916 16.1362 44.916 17.3328C44.916 18.5295 43.946 19.4995 42.7494 19.4995H33.7998C33.7768 19.4995 33.7538 19.4995 33.7307 19.4995C33.1839 19.4996 32.6514 19.4996 32.2004 19.4628C31.7051 19.4223 31.1206 19.3268 30.5326 19.0272C29.7172 18.6117 29.0543 17.9488 28.6388 17.1335C28.3392 16.5454 28.2437 15.961 28.2032 15.4657C28.1664 15.0147 28.1664 14.4821 28.1665 13.9353C28.1665 13.9123 28.1665 13.8892 28.1665 13.8662V4.91667C28.1665 3.72005 29.1366 2.75 30.3332 2.75Z" fill="#DADFF1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.3338 25.1328C44.5304 25.1328 45.5004 26.1029 45.5004 27.2995V37.3556C45.5005 39.0997 45.5005 40.5392 45.4047 41.7118C45.3052 42.9296 45.0916 44.0492 44.5558 45.1007C43.7249 46.7315 42.3991 48.0573 40.7683 48.8882C39.7168 49.424 38.5973 49.6376 37.3794 49.7371C36.2068 49.8329 34.7673 49.8328 33.0232 49.8328H32.9338C31.7371 49.8328 30.7671 48.8628 30.7671 47.6661C30.7671 46.4695 31.7371 45.4995 32.9338 45.4995C34.7897 45.4995 36.0513 45.4978 37.0265 45.4181C37.9764 45.3405 38.4622 45.1998 38.8011 45.0272C39.6164 44.6117 40.2793 43.9488 40.6948 43.1334C40.8674 42.7946 41.0081 42.3088 41.0857 41.3589C41.1654 40.3837 41.1671 39.1221 41.1671 37.2661V27.2995C41.1671 26.1029 42.1371 25.1328 43.3338 25.1328Z" fill="#DADFF1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.89476 32.6425C6.77654 33.0685 6.21537 34.3204 6.64136 35.4386L11.5937 48.4386C11.9126 49.2755 12.7134 49.83 13.609 49.8339C14.5046 49.8378 15.3103 49.2903 15.6364 48.4562L19.3507 38.9562C19.7864 37.8417 19.2362 36.5851 18.1217 36.1493C17.0072 35.7136 15.7506 36.2638 15.3148 37.3783L13.6447 41.6499L10.6908 33.8959C10.2648 32.7777 9.01299 32.2165 7.89476 32.6425Z" fill="#DADFF1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.7722 32.6425C27.8905 33.0685 28.4516 34.3204 28.0256 35.4386L23.0732 48.4386C22.7544 49.2755 21.9536 49.83 21.058 49.8339C20.1624 49.8378 19.3567 49.2903 19.0306 48.4562L15.3163 38.9562C14.8806 37.8417 15.4308 36.5851 16.5453 36.1493C17.6597 35.7136 18.9164 36.2638 19.3522 37.3783L21.0223 41.6499L23.9762 33.8959C24.4022 32.7777 25.654 32.2165 26.7722 32.6425Z" fill="#DADFF1"/>
  </svg>
</template>

<script>
export default {
  name: "WordFormFileIcon"
}
</script>

<style scoped>

</style>