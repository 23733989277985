import {mapGetters} from 'vuex';
import {COUNTERPARTIES__EDIT} from '@/configs/events';

export default {
  data() {
    return {
      showContent: true,
    };
  },
  computed: {
    ...mapGetters(['accessToEvent']),
    showAdd() {
      return this.accessToEvent(COUNTERPARTIES__EDIT);
    },
    amount() {
      const getterName = this.$vuexAdapter.getNameCountGetter(this.endPoint);
      return this.$store.getters[getterName];
    },
  },
  methods: {
    toggle(show) {
      this.showContent = show;
    },
    add() {
      this.$refs.addModal.show();
    },
  },
};
