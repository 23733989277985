<template>
  <div :class="classDocumentSheetContainer"
       ref="componentContainer">
    <div v-if="!initFirstDataPreviewGetter"
         class="document-init-attempted p-5">
      <div class="spinner-alpha-container mb-3">
        <i class="spinner-alpha"></i>
      </div>
      <span class="text-muted">Загрузка данных</span>
    </div>
    <div v-else>
      <sheet-document-preview :ready="!clientGetterLoader">
        <header-client-preview :client="clientGetter"/>
        <common-client-preview :client="clientGetter"/>
      </sheet-document-preview>

      <sheet-document-preview :ready="!clientAddressLoader">
        <addresses-client-preview :client-id="entityIdPreviewGetter"/>
      </sheet-document-preview>

      <sheet-document-preview :ready="!clientContactsLoader">
        <contacts-client-preview :client-id="entityIdPreviewGetter"/>
      </sheet-document-preview>

      <sheet-document-preview :ready="!clientCommentLoader">
        <comments-client-preview :client-id="entityIdPreviewGetter"/>
      </sheet-document-preview>

      <sheet-document-preview v-if="accessToBankDetails"
                              :ready="!clientBankDetailsLoader">
        <bank-details-client-preview :client-id="entityIdPreviewGetter"/>
      </sheet-document-preview>

      <sheet-document-preview :ready="!clientDocLoader">
        <nearest-docs-client-preview :client-id="entityIdPreviewGetter">
        </nearest-docs-client-preview>
      </sheet-document-preview>
    </div>
  </div>
</template>

<script>
import preview from "@/mixins/preview";
import {mapGetters} from "vuex";
import SheetDocumentPreview from "@/components/doc/preview/SheetDocumentPreview";
import HeaderClientPreview from "@/components/clients/HeaderClientPreview";
import CommonClientPreview from "@/components/clients/CommonClientPreview";
import NearestDocsClientPreview from "@/components/clients/NearestDocsClientPreview";
import AddressesClientPreview from "@/components/clients/AddressesClientPreview";
import ContactsClientPreview from "@/components/clients/ContactsClientPreview";
import BankDetailsClientPreview from "@/components/clients/BankDetailsClientPreview";
import VuexAdapter from "@/services/vuexAdapter";
import {CLIENTS_ADDRESSES_GET, CLIENTS_BANK_DETAILS_GET, CLIENTS_COMMENTS_GET, CLIENTS_CONTACTS_GET, CLIENTS_DOCS_GET, CLIENTS_GET} from "@/configs/endPoints";
import CommentsClientPreview from "@/components/clients/CommentsClientPreview";
import {COUNTERPARTIES__BANK_DETAILS} from "@/configs/events";
import {ClientPreview} from "@/services/preview/ClientPreview";

export default {
  name: "ClientPreview",
  components: {
    CommentsClientPreview,
    BankDetailsClientPreview,
    ContactsClientPreview,
    AddressesClientPreview,
    NearestDocsClientPreview,
    CommonClientPreview,
    HeaderClientPreview,
    SheetDocumentPreview
  },
  mixins: [preview],
  created() {
    this.initPreviewAction(ClientPreview.intefaceIdent);
  },
  computed: {
    ...mapGetters({
      clientGetter: VuexAdapter.getNameRowGetter(CLIENTS_GET),
      clientGetterLoader: VuexAdapter.getNameRowLoaderGeneralGetter(CLIENTS_GET),
      clientAddressLoader: VuexAdapter.getNameLoaderGeneralGetter(CLIENTS_ADDRESSES_GET),
      clientBankDetailsLoader: VuexAdapter.getNameLoaderGeneralGetter(CLIENTS_BANK_DETAILS_GET),
      clientCommentLoader: VuexAdapter.getNameLoaderGeneralGetter(CLIENTS_COMMENTS_GET),
      clientContactsLoader: VuexAdapter.getNameLoaderGeneralGetter(CLIENTS_CONTACTS_GET),
      clientDocLoader: VuexAdapter.getNameLoaderGeneralGetter(CLIENTS_DOCS_GET),
    }),
    accessToBankDetails() {
      return this.accessToEvent(COUNTERPARTIES__BANK_DETAILS);
    },
  },
}
</script>