<template>
  <modal-ui ref="modal" title="Добавление адреса">
    <template #body>
      <form-builder ref="formBuilder" v-model="form" :fields="fields"/>
    </template>

    <template #footer>
      <close-button @click="hide"/>
      <add-button @click="apply"/>
    </template>
  </modal-ui>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddButton from "@/components/buttons/AddButton";
import CloseButton from "@/components/buttons/CloseButton";
import VuexAdapter from "@/services/vuexAdapter";
import {ADDRESS_ADD, ADDRESS_TYPES_GET, CLIENTS_ADDRESSES_GET} from "@/configs/endPoints";
import FormBuilder from "@/components/form/FormBuilder.vue";
import addressClientsForm from "@/configs/forms/clients/address";
import {ERROR_NOTIFY_TYPE} from "@/configs/notifyTypes";
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';

const form = {
  comment: '',
  address_type: null,
  address: '',
}

export default {
  name: "AddAddressModal",
  components: {ModalUi, FormBuilder, CloseButton, AddButton},
  mixins: [modal],
  props: {
    parentId: {
      type: Number,
      required: true
    },
    parentTableId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    form,
    fields: [],
  }),
  created() {
    this.getClientAddressTypesAction({
      where: 'PARENT_TABLE_ID = ' + this.parentTableId
    });
    this.fields = addressClientsForm(this);
  },
  computed: {
    ...mapGetters({
      clientAddressTypesGetter: VuexAdapter.getNameSelectGetter(ADDRESS_TYPES_GET),
    })
  },
  methods: {
    ...mapActions({
      refreshPartlyPreviewAction: 'refreshPartlyPreviewAction',
      addAddressAction: VuexAdapter.getNameAction(ADDRESS_ADD),
      getClientAddressTypesAction: VuexAdapter.getNameAction(ADDRESS_TYPES_GET),
    }),
    apply() {
      if (!this.$refs.formBuilder.validate()) {
        return;
      }

      const data = {
        ...this.form,
        parent_id: this.parentId,
        parent_table_id: this.parentTableId,
        address_type: this.form.address_type?.label,
      };

      this.showLoader();

      this.addAddressAction(data).then(() => {
        this.refreshPartlyPreviewAction(CLIENTS_ADDRESSES_GET);
        this.hide();
        this.form = form;
      }).catch(e => {
        this.$notify({
          title: 'Ошибка добавления адреса',
          type: ERROR_NOTIFY_TYPE,
          text: e?.error_message || 'Неизвестная ошибка',
        });
        this.hideLoader();
      });
    },
  }
}
</script>
