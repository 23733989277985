export const only = (...array) => value => array.includes(value);

export const keys = (...array) => value => {
    if (Array.isArray(value)) {
        return value.every(keys(...array));
    }

    const objectKeys = Object.keys(value);
    return array.every(key => objectKeys.includes(key));
};
