import HttpService from '@/services/http';
import {
  SYSTEM_FILE_ADD_WITH_CONTENT,
  SYSTEM_FILE_ADDVIEW,
  SYSTEM_FILE_EDITION_GET,
  SYSTEM_FILE_META_GET
} from "@/configs/endPoints";

export default {
  actions: {
    async actionFileLastVersion(ctx, data) {
      return await HttpService.post(SYSTEM_FILE_EDITION_GET, data);
    },
    async actionUploadFile(ctx, data) {
      return await HttpService.post(SYSTEM_FILE_ADD_WITH_CONTENT, data);
    },
    async actionUploadEdits(ctx, data) {
      return await HttpService.post('system/file/add/with/edits/', data);
    },
    async actionFileAddPreview(ctx, file_ids) {
      return await HttpService.post(SYSTEM_FILE_ADDVIEW, {
        file_ids
      });
    },
    async actionFilePreviewMetaGet(ctx, file_hash) {
      return await HttpService.post(SYSTEM_FILE_META_GET, {
        file_hash
      }).then((r) => {
        ctx.commit('mutationFilePreviewMetaGet', r);
      });
    },
  },
  mutations: {
    mutationFilePreviewMetaGet(state, data) {
      state.filePreviewMeta = data.data.count_pages ?? null;
    },
    clearFilePreviewMutations(state) {
      state.filePreviewMeta = {};
    },
  },
  state: {
    filePreviewMeta: {}
  },
  getters: {
    getterFilePreviewMetaGet(state) {
      return state.filePreviewMeta;
    },
  }
}
