<template>
  <div class="files-preview-content">
    <div v-for="file in files_previews" v-bind:key.prop="file" class="mt-3">
      <file-preview :file="file" :show-edit="showEdit"/>
    </div>
  </div>
</template>

<script>
import FilePreview from "@/components/doc/files/FilePreview";
import VALID_FILE_TYPES from '@/configs/validFileTypes';

export default {
  name: "FilesPreview",
  data: () => ({
    file_ids: [],
    files_previews: [],
  }),
  components: {
    FilePreview
  },
  props: ['files', 'showEdit'],
  created() {
    if (this.files.length > 0) {
      this.addViewFile();
      this.$eventBus.$on('document-preview-scroll-bottom', this.addViewFile);
    }
  },
  methods: {
    extensionValid(fileName) {
      const extension = fileName.split('.').pop().toLowerCase();
      return VALID_FILE_TYPES.includes(extension)
    },
    addViewFile() {
      for (let key in this.files) {
        let file_id = this.files[key].F_ID;
        if (this.file_ids.includes(file_id) || !this.extensionValid(this.files[key]['Название'])) {
          continue;
        }
        this.file_ids.push(file_id);
        this.files_previews.push(this.files[key]);
        if (this.file_ids.length >= 2) {
          break;
        }
      }
    }
  },
  beforeDestroy() {
    if (this.files.length > 0) {
      this.$eventBus.$off('document-preview-scroll-bottom');
    }
  }
}
</script>
