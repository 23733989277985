<template>
  <div class="form--date">
    <label v-if="label" v-html="label"/>
    <div class="form--date__wrapper" :class="{'form--date__wrapper_disabled': disabledAll}">
      <div v-if="!onlyCalendar" class="form--date__input__date" :class="inputClasses">
        <div @click="dateTo(-1)" class="form--date__input__date--button">
          <collapse-icon v-if="!disabledAll"/>
        </div>
        <div class="form--date__input__date--input">
          <input
            v-maska="'##.##.####'"
            v-model="valueInput"
            @focus="focusInput"
            @blur="blurInput"
            @change="changeInput"
            :disabled="disabledAll"
            :placeholder="placeholder"
          >
        </div>
        <div @click="dateTo(1)" class="form--date__input__date--button">
          <chevron-right-icon v-if="!disabledAll"/>
        </div>
      </div>
      <template v-if="!onlyInput">
        <date-range-picker :locale-data="dateRangePickerConfig"
                           :linked-calendars="false"
                           :date-range="dateRange"
                           :show-dropdowns="false"
                           :auto-apply="true"
                           :disabled="disabledAll"
                           :ranges="false"
                           opens="left"
                           single-date-picker="default"
                           v-model="picker"
                           @update="updateDate($event)"
        >
          <template v-slot:input>
            <calendar-plus-icon/>
          </template>
        </date-range-picker>
        <div v-if="!onlyCalendar"
             @click="setToday"
             class="form--date__input__date--today">
          <today-icon/>
        </div>
        <div v-if="!onlyCalendar && copyHandler"
             @click="copyHandler"
             class="form--date__input__date--today">
          <copy-icon/>
        </div>
      </template>
    </div>
    <span
        v-if="caption || error"
        class="form--input-caption"
        :class="error ? 'form--input-caption__error' : ''"
    >
			{{ error ? error : caption }}
		</span>
  </div>
</template>

<script>
import CalendarPlusIcon from "@/components/icons/CalendarPlusIcon";
import CollapseIcon from "@/components/icons/CollapseIcon";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon";
import {DateTime} from "luxon";
import TodayIcon from "@/components/icons/TodayIcon";
import CopyIcon from "@/components/icons/CopyIcon";
import {vMaska} from 'maska/vue';

export default {
  name: "DateForm",
  components: {CopyIcon, TodayIcon, ChevronRightIcon, CollapseIcon, CalendarPlusIcon},
  directives: {
    Maska: vMaska,
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * Значение поля
     * @type {Object}
     */
    value: {
      type: String,
      default: null
    },
    /**
     * Текст для <label>
     * @type {string}
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Аттрибут [placeholder]
     * @type {string}
     */
    placeholder: {
      type: String,
      default: '__.__.____'
    },
    /**
     * Изначальное значение поля
     * @type {Object}
     */
    initialValue: {
      type: Object,
      default: null,
    },
    /**
     * Показывать только кнопку выбора даты
     * @type {Object}
     */
    onlyCalendar: {
      type: Boolean,
      default: false,
    },
    /**
     * Показывать только ввод даты
     * @type {Object}
     */
    onlyInput: {
      type: Boolean,
      default: false,
    },
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Аттрибут [readonly]
     * @type {boolean}
     */
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * Сообщение об ошибке
     * @type {string}
     */
    error: {
      type: String,
      default: ''
    },
    /**
     * Подсказка
     * @type {string}
     */
    caption: {
      type: String,
      default: ''
    },
    /**
     * Кнопка копировать
     */
    copyHandler: {
      type: Function,
      default: undefined
    },
    classes: {
      type: Array,
      default: undefined
    },
  },
  data() {
    return {
      focus: false,
      valueInput: null,
      picker: {
        startDate: null,
        endDate: null,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      dateRangePickerConfig: {
        direction: 'ltr',
        format: 'dd.mm.yyyy',
        applyLabel: 'Сохранить',
        Today: 'Сохранить',
        cancelLabel: 'Отмена',
        weekLabel: 'W',
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сенябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        firstDay: 1,
      },
      form: {
        day: '',
        month: '',
        year: '',
      },
      dateTime: null,
    }
  },
  beforeMount() {
    if (this.initialValue) {
      this.valueInput = this.initialValue;
      this.changeInput();
    }
    if (this.value) {
      this.dateTime = DateTime.fromFormat(this.value, 'yyyy-MM-dd');
    }
  },
  computed: {
    disabledAll() {
      return this.disabled || this.readonly
    },
    inputClasses() {
      const res = [];

      if (this.error) res.push('error');
      if (this.focus) res.push('focus');

      if (Array.isArray(this.classes))  {
        this.classes.forEach(c => {
          res.push(c);
        })
      }

      return res;
    },
  },
  methods: {
    focusInput() {
      this.focus = true;
      this.$emit('focus');
    },
    blurInput() {
      this.focus = false;
      this.$emit('blur');
    },
    changeInput() {
      this.dateTime = DateTime.fromFormat(this.valueInput, 'dd.MM.yyyy');
    },
    getDate() {
      if (this.dateTime === null || this.dateTime.invalid) {
        this.dateTime = new DateTime(new Date());
      }

      return this.dateTime;
    },
    dateTo(to) {
      if (!this.disabledAll) {
        this.dateTime = this.getDate().plus({days: to});
      }
    },
    updateDate(value) {
      this.dateTime = new DateTime.fromJSDate(new Date(value.startDate));
    },
    setToday() {
      if (!this.disabledAll) {
        this.dateTime = new DateTime(new Date());
      }
    },
  },
  watch: {
    dateTime() {
      if (this.dateTime?.invalid) {
        this.valueInput = null;
        this.dateTime = null;
        this.$emit('change', null);

        return;
      }

      this.valueInput = this.dateTime?.toFormat('dd.MM.yyyy');
      this.$emit('change', this.dateTime?.toFormat('yyyy-MM-dd'));

    },
    value(modelValue) {
      this.dateTime = modelValue ? DateTime.fromFormat(modelValue, 'yyyy-MM-dd') : null;
    },
  }
}
</script>
