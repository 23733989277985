import HttpService from '@/services/http';

export default {
  actions: {
    /* Получить счетчики */
    async getDocCountersAction(ctx, doc_id) {
      return await HttpService.post('doc/counters/', {doc_id}).then((r) => {
        ctx.commit('docCountersMutations', r);
      })
    },

    /* Очисть хранилище */
    clearDocCountersAction(ctx) {
      ctx.commit('clearDocCountersMutations');
    }
  },
  mutations: {
    docCountersMutations(state, data) {
      state.comments_count = data.data.comments_count;
      state.files_count = data.data.files_count;
      state.links_count = data.data.links_count;
      state.contractor_count = data.data.contractor_count;
      state.views_count = data.data.views_count;
    },
    clearDocCountersMutations(state) {
      state.comments_count = null;
      state.files_count = null;
      state.links_count = null;
      state.contractor_count = null;
      state.views_count = null;
    },
  },
  state: {
    comments_count: null,
    files_count: null,
    links_count: null,
    contractor_count: null,
    views_count: null,
  },
  getters: {
    docCounterCommentsGetter(state) {
      return state.comments_count
    },
    docCounterFilesGetter(state) {
      return state.files_count
    },
    docCounterLinksGetter(state) {
      return state.links_count
    },
    docCounterContractorGetter(state) {
      return state.contractor_count
    },
    docCounterViewsGetter(state) {
      return state.views_count
    },
  },
}
