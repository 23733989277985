<template>
  <div class="array-form">
    <search-legacy-ui
      ref="search"
      :value-field="searchValueField"
      :key-field="searchKeyField"
      :end-point="searchEndPoint"
      :placeholder="searchPlaceholder"
      :disabled-formatter="disabledFormatter"
      :disabled="disabled"
      v-model="searchModel"
      @change="search"
    />
    <div class="array-form--wrapper">
      <label v-if="label" v-html="label"/>
      <div class="array-form--wrapper__square"
           :class="{
              'array-form--wrapper__square--empty': isEmpty,
              'array-form--wrapper__square--not-empty': !isEmpty,
           }">
        <loader-ui v-if="loading"/>
        <template v-if="isEmpty">
          <div class="array-form--wrapper__square--empty__button" @click="$refs.search.focus()">
            <component :is="buttonIcon" class="array-form--wrapper__square--empty__button--icon"/>
            <div class="array-form--wrapper__square--empty__button--text">{{ buttonText }}</div>
          </div>
          <div v-if="buttonAfterInfo" class="array-form--wrapper__square--empty__info">
            {{ buttonAfterInfo }}
          </div>
        </template>
        <template v-else>
          <div v-for="(item, key) in modelProxy"
               :key="item[searchKeyField]"
               class="array-form--wrapper__square--item">
            <div class="array-form--wrapper__square--item__header">
              <a :href="itemTitleHrefHandler(item)"
                 v-html="itemTitleHandler(item)"
                 target="_blank"/>
              <info-item-array-form/>
            </div>
            <div class="array-form--wrapper__square--item__icon-container">
              <component :is="itemIcon" class="item-icon"/>
              <div v-if="!disabled" @click="remove(item)" class="trash-icon">
                <trash-icon/>
              </div>
            </div>
            <div class="array-form--wrapper__square--item__select">
              <combobox-legacy-ui
                :options="options"
                v-model="item[selectKey]"
                :disabled="disabled"
                :no-search="hideSelectSearch"
                code-only-value
                no-clear
                @change="changeSelect($event, item)"
              />
            </div>
            <div v-if="isLast(key)" class="array-form--wrapper__square--item--add-icon" @click="$refs.search.focus()">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9945 1H7.00573C3.69209 1 1.00586 3.68623 1.00586 6.99987V24.9995C1.00586 28.3131 3.69209 30.9993 7.00573 30.9993H15.9945H16.0055H24.9943C28.3079 30.9993 30.9941 28.3131 30.9941 24.9995V6.99987C30.9941 3.68623 28.3079 1 24.9943 1H16.0055H15.9945ZM16.0001 7.8332C15.1742 7.83615 14.5055 8.50657 14.5055 9.33316V14.4997H9.33899C8.51058 14.4997 7.83903 15.1713 7.83903 15.9997C7.83903 16.8281 8.51058 17.4996 9.33899 17.4996H14.5055V22.6662C14.5055 23.4928 15.1741 24.1632 16 24.1662C16.8259 24.1632 17.4945 23.4928 17.4945 22.6662V17.4996H22.661C23.4894 17.4996 24.161 16.8281 24.161 15.9997C24.161 15.1713 23.4894 14.4997 22.661 14.4997H17.4945V9.33315C17.4945 8.50661 16.8259 7.83622 16.0001 7.8332Z"/>
              </svg>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import SearchLegacyUi from '@/components/ui/SearchLegacyUi.vue';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import InfoItemArrayForm from '@/components/icons/InfoItemArrayForm.vue';
import TrashIcon from '@/components/icons/TrashIcon.vue';
import ComboboxLegacyUi from '@/components/ui/ComboboxLegacyUi.vue';

export default {
  name: 'ArrayForm',
  components: {ComboboxLegacyUi, TrashIcon, InfoItemArrayForm, LoaderUi, SearchLegacyUi},
  data: () => ({
    searchModel: null,
    modelProxy: [],
    loading: false,
  }),
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    searchValueField: {
      type: String,
      required: true,
    },
    selectKey: {
      type: String,
      required: true,
    },
    searchKeyField: {
      type: String,
      required: true,
    },
    disabledFormatter: {
      type: Function,
      required: true,
    },
    searchEndPoint: {
      type: String,
      required: true,
    },
    searchPlaceholder: {
      type: String,
      required: true,
    },
    searchHandler: {
      type: Function,
      default: async (id) => {
        return {id};
      },
    },
    label: {
      type: String,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonAfterInfo: {
      type: String,
      default: undefined,
    },
    buttonIcon: {
      type: Function,
      required: true,
    },
    itemIcon: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideSelectSearch: {
      type: Boolean,
      default: false,
    },
    itemTitleHandler: {
      type: Function,
      default: (item) => {
        return item;
      },
    },
    itemTitleHrefHandler: {
      type: Function,
      default: (item) => {
        console.log(item);
        return '#';
      },
    },
    afterAddHandler: {
      type: Function,
      default: item => item,
    },
  },
  created() {
    this.modelProxy = this.modelValue || [];
  },
  computed: {
    isEmpty() {
      return !this.modelValue || this.modelValue.length === 0;
    },
  },
  methods: {
    isLast(key) {
      return key === this.modelProxy.length - 1 && !this.disabled;
    },
    async search(option) {
      if (!option) {
        return;
      }
      this.$nextTick(() => this.searchModel = null);

      if (this.modelProxy.some(i => i[this.searchKeyField] === option.code)) {
        return;
      }

      this.loading = true;
      const item = await this.searchHandler(option.code);
      this.modelProxy.push(this.afterAddHandler(item));
      this.loading = false;
      this.change();
    },
    remove(item) {
      this.modelProxy = this.modelProxy.filter(i => i[this.searchKeyField] !== item[this.searchKeyField]);
      this.change();
    },
    change() {
      this.$emit('change', this.modelProxy);
    },
    changeSelect(e, item) {
      this.$set(item, this.selectKey, e);
      this.change();
    },
  },
};
</script>

<style scoped lang="scss">
.search-ui {
  margin-bottom: 8px;
}
</style>
