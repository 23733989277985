<template>
    <div class="ratio-a4 d-inline-flex justify-content-center align-items-center w-100">
        <div class="text-center">
            <div class="spinner-alpha-container mb-3 ml-5">
                <font-awesome-icon :icon="['fas', 'cloud-download-alt']"
                                   :style="{ color: '#d0d0d0', width: '50px', height: '50px' }"/>
                <font-awesome-icon :icon="['fas', 'times']"
                                   :style="{ color: '#a00000', width: '50px', height: '50px'}"
                                   transform="down-3 left-10"/>
            </div>
            <span class="text-muted">Ошибка отображения файла</span><br>
            <a href="#" v-on:click.prevent="$emit('restartLoad')" class="pointer-event">Попробовать снова
                <font-awesome-dynamic-icon :icon="['fas', 'redo']"/>
            </a>
        </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default {
    name: 'FileFailPreview',
    components: {
        FontAwesomeIcon,
    },
}
</script>