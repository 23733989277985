import HttpService from '@/services/http';
import {USER_FIO, USER_ID} from '@/configs/endPoints';

export default {
  namespaced: true,
  state: {
    userId: null,
    userName: null,
  },
  actions: {
    async fetch({commit}) {
      const idResponse = await HttpService.post(USER_ID);
      const userId = idResponse.data.login_id;

      const nameResponse = await HttpService.post(USER_FIO, {login_id: userId});
      const userName = nameResponse.data.user_fio;

      commit('set', {userId, userName});
    },
  },
  mutations: {
    set(state, payload) {
      state.userId = payload.userId;
      state.userName = payload.userName;
    },
    clear(state) {
      state.userId = null;
      state.userName = null;
    },
  }
}
