<template>
  <div class="toolbar-wrapper" :class="{ 'toolbar-collapsed': isCollapsedState }" @click="onToolbarClick">
    <div class="d-flex align-items-center" v-if="showButtonMenu || showButtonBack">
      <div class="mr-1 ml-2 align-self-center">
        <button v-if="showButtonMenu"
                class="btn btn-mobile-menu"
                @click="mobileMenuToggle">
          <menu-bars-icon/>
        </button>
        <button v-if="showButtonBack"
                class="btn btn-mobile-menu"
                @click="setActivePreview(false)">
          <left-circle-icon style="flex-shrink: 0;"/>
        </button>
      </div>
    </div>
    <div class="toolbar-content">
      <div class="d-flex align-items-center justify-content-between">
        <div @click="scrollTop" class="page-title">
          {{ textHeader }}
        </div>
        <add-button v-if="showAdd" @click="addClick" class="mr-2" :class="{
          'btn-alpha-add-doc__small': isSmall
        }">
          <template #title>{{ addButtonTitle }}</template>
        </add-button>
        <master-modal ref="masterModal" :master="standardMaster"/>
        <reload-button v-if="$route.meta.table"
                       @click="reloadTable"/>
        <component v-if="posibleShowInformer"
                   :is="$route.meta.informer"
                   view="informer"/>
      </div>
      <toolbar-right :is-collapsed="isCollapsedState"/>
    </div>
  </div>
</template>

<script>
import Constants from '@/configs/constants';
import ToolbarRight from '@/components/general/ToolbarRight';
import ReloadButton from '@/components/buttons/ReloadButton';
import MenuBarsIcon from '@/components/icons/MenuBarsIcon';
import LeftCircleIcon from '@/components/icons/LeftCircleIcon';
import AddButton from "@/components/buttons/AddButton";
import {mapActions, mapGetters} from "vuex";
import VuexAdapter from "@/services/vuexAdapter";
import {DOC_ADD, DOC_ROUTE_POINT_ADD} from "@/configs/endPoints";
import MasterModal from "@/components/modals/MasterModal";
import standardMaster from "@/configs/master/standardMaster";
import {VCHT_ORG_INDENT} from "@/configs/orgIdents";

export default {
  name: 'ToolbarGeneral',
  data: () => ({
    standardMaster
  }),
  inject: [
    'app',
    'layout',
  ],
  components: {
    MasterModal,
    AddButton,
    ToolbarRight,
    ReloadButton,
    MenuBarsIcon,
    LeftCircleIcon,
  },
  computed: {
    ...mapGetters(['isActivePreviewGetter']),
    isSmall() {
      return this.app.appWidth < Constants.resolutionPoints.small;
    },
    textHeader() {
      return this.isSmall ? this.$route.meta.titleSmall : this.$route.meta.title;
    },
    addButtonTitle() {
      return this.isSmall ? '' : 'Добавить';
    },
    showButtonBack() {
      return this.layout?.isSlideMode && this.isActivePreviewGetter;
    },
    showButtonMenu() {
      return this.layout?.isSlideMode && !this.showButtonBack;
    },
    posibleShowInformer() {
      return this.$route.meta.informer !== undefined
        && this.app.appWidth >= Constants.resolutionPoints.tinys
    },
    isCollapsedState() {
      return !!this.layout?.isSlideMode || this.app.appHeight < Constants.resolutionPoints.tiny;
    },
    showAdd() {
      if (this.$store.getters['auth/getOrgIdent'] !== VCHT_ORG_INDENT) {
        return false;
      }

      return ['controls'].includes(this.$route.name);
    },
  },
  methods: {
    ...mapActions({
      refreshPreview: 'refreshPreviewAction',
      setActivePreview: 'setActivePreviewAction',
      docRoutePointAdd: VuexAdapter.getNameAction(DOC_ROUTE_POINT_ADD),
      docAddAction: VuexAdapter.getNameAction(DOC_ADD),
    }),
    mobileMenuToggle() {
      this.$eventBus.$emit('mobile-menu-show');
    },
    scrollTop() {
      this.layout?.frameLeft.scrollTo({behavior: 'smooth', top: 0});
      this.layout?.frameRight.scrollTo({behavior: 'smooth', top: 0});
    },
    reloadTable() {
      this.$eventBus.$emit('table-start-reload-' + this.$route.meta?.table);
      this.refreshPreview();
    },
    addClick() {
      this.$refs.masterModal.show();
    },
    onToolbarClick() {
      this.$eventBus.$emit('toolbar-click');
    },
  }
}
</script>
