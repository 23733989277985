<template>
  <modal-ui ref="modal" title="Новая точка маршрута" prevent-closure @shown="onShown" @hidden="onHidden">
    <template #body>
      <div class="info">
        Введите информацию о точке маршрута.<br>
        Учтите, что документ начнет двигаться по маршруту только после его отправки в работу.
      </div>

      <form-builder
        v-model="form"
        ref="formBuilder"
        :fields="routePointForm"
        @change="changeFrom = true"
      >
        <template #copy-content="{modelValue}">
          <button-icon-ui
            v-if="!modelValue"
            size="l"
            color="outline"
            :disabled="!doc?.['Содержание']?.length"
            @click="copyContent"
          >
            <copy-icon></copy-icon>
          </button-icon-ui>
        </template>

        <template #copy-comment="{modelValue}">
          <button-icon-ui
            v-if="!modelValue"
            size="l"
            color="outline"
            :disabled="!doc?.['Комментарий']?.length"
            @click="copyComment"
          >
            <copy-icon></copy-icon>
          </button-icon-ui>
        </template>
      </form-builder>

      <attention-modal
        ref="attentionAddRoutePointModal"
        :message="attentionMessage"
        @continueClosing="hide"
      ></attention-modal>
    </template>

    <template #footer>
      <checkbox-form v-model="closeForm" label="Закрывать форму"/>

      <div class="d-flex align-items-center justify-content-between" style="margin: 0 0 0 auto;">
        <close-button @click="showAttentionAddRoutePointModal" class="mr-2"/>
        <add-button @click="apply"/>
      </div>
    </template>
  </modal-ui>
</template>

<script>
import {defineComponent} from 'vue';
import CloseButton from '@/components/buttons/CloseButton';
import AddButton from '@/components/buttons/AddButton';
import {mapActions, mapGetters} from 'vuex';
import routePointTypes from '@/configs/routePointTypes';
import VuexAdapter from '@/services/vuexAdapter';
import {
  DOC_GANTT_ITEMS,
  DOC_ROUTE_POINT_ADD,
  DOCS_GET,
  ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET,
} from '@/configs/endPoints';
import FormBuilder from '@/components/form/FormBuilder';
import routePoint from '@/configs/forms/routePoint';
import CheckboxForm from '@/components/form/CheckboxForm';
import {fullTrim} from '@/services/utilsFunctions';
import {DateTime} from 'luxon';
import AttentionModal from '@/components/modals/AttentionModal';
import {ERROR_NOTIFY_TYPE, SUCCESS_NOTIFY_TYPE} from '@/configs/notifyTypes';
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';
import CopyIcon from '@/assets/svg/copy.svg?component';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';

const server = 'server';
const all = 'all';
const days = 'days';
const end_date = 'end_date';

const form = {
  links: [],
  responsible: null,
  rpt_id: null,
  end_date: null,
  termFrom: days,
  days: 1,
  content: '',
  comment: '',
  responsibleFrom: server,
};

export default defineComponent({
  name: 'AddRoutePointModal',
  components: {
    ButtonIconUi,
    CopyIcon,
    ModalUi,
    AttentionModal,
    CheckboxForm,
    FormBuilder,
    AddButton,
    CloseButton,
  },
  mixins: [modal],
  props: {
    docId: {
      type: Number,
      required: true,
    },
    rptId: {
      type: Number,
      default: null,
    },
    parents: {
      type: String,
      default: null,
    },
  },
  data() {
    const routePointForm = routePoint(this);
    return {
      comment: '',
      server,
      all,
      days,
      end_date,
      typesOptions: [
        {
          code: routePointTypes.assignment,
          label: 'Поручение',
        },
        {
          code: routePointTypes.agreement,
          label: 'Согласование',
        },
        {
          code: routePointTypes.notification,
          label: 'Уведомление',
        },
      ],
      form: {...form},
      routePointForm,
      closeForm: true,
      doc: null,
      changeFrom: false,
      attentionMessage: 'Вы собираетесь закрыть окно <br>"Новая точка маршрута".<br> Введенные данные не будут сохранены.<br> Продолжить?',
    };
  },
  computed: {
    fetchDataAfterRun() {
      return {
        doc_id: this.docId,
        rp_id: 0,
        termFromInDays: this.termFromInDays,
        date: this.form.end_date,
      };
    },
    keyDataAfterRun() {
      return JSON.stringify(this.fetchDataAfterRun);
    },
    showRunAfter() {
      return !this.parents;
    },
    termFromInDays() {
      return this.form.termFrom == days;
    },
    optionsResponsible() {
      let options = [];
      this.routePointResponsibleGetter.forEach((responsible) => {
        if (this.form.responsibleFrom === responsible['Сервер'] && this.hasSystemServerIdGetter && this.getSystemServerIdGetter !== responsible['Сервер']) {
          return;
        }
        options.push({
          code: `${responsible.RESPONSIBLE_TABLE_ID}_${responsible.RESPONSIBLE_ID}`,
          label: responsible['Исполнитель'],
          data: {
            responsible_table_id: responsible.RESPONSIBLE_TABLE_ID,
            responsible_id: responsible.RESPONSIBLE_ID,
          },
        });
      });

      return options;
    },
    ...mapGetters(['getSystemServerIdGetter', 'hasSystemServerIdGetter', 'selectedOptionsAfterRunRoutePointGetter']),
    ...mapGetters({
      routePointResponsibleGetter: VuexAdapter.getNameGetter(ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET),
    }),
  },
  methods: {
    ...mapActions(['systemServerIdAction', 'refreshPartlyPreviewAction', 'fetchAfterRunRoutePointAction', 'clearAfterRunRoutePointAction']),
    ...mapActions({
      getRoutePointResponsibleAction: VuexAdapter.getNameAction(ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET),
      docRoutePointAddAction: VuexAdapter.getNameAction(DOC_ROUTE_POINT_ADD),
      fetchDocumentAction: VuexAdapter.getNameRowOnlyFetchAction(DOCS_GET),
    }),
    showAttentionAddRoutePointModal() {
      if (!this.changeFrom) {
        this.hide();

        return;
      }

      this.$refs.attentionAddRoutePointModal.show();
    },
    async onShown() {
      this.showLoader();

      this.fetchDocumentAction(this.docId)
        .then(r => this.doc = r);

      if (!this.hasSystemServerIdGetter) {
        await this.systemServerIdAction();
      }
      await this.getRoutePointResponsibleAction();

      if (this.showRunAfter) {
        await this.fetchAfterRunRoutePointAction(this.fetchDataAfterRun);
      }

      if (this.rptId) {
        this.typesOptions.forEach((type) => {
          if (type.code == this.rptId) {
            this.form.rpt_id = type;
          }
        });
      }
      this.hideLoader();
      this.changeFrom = false;
    },
    onHidden() {
      this.clearAfterRunRoutePointAction();
      this.form = {...form};
    },
    async apply() {
      try {
        if (!this.$refs.formBuilder.validate()) {
          return;
        }
        this.showLoader();

        const data = {
          ...this.form,
          doc_id: this.docId,
          responsible_table_id: this.form.responsible.data.responsible_table_id,
          responsible_id: this.form.responsible.data.responsible_id,
          rpt_id: this.form.rpt_id.code,
          links: this.showRunAfter && this.form.links && this.form.links.length
            ? '#' + this.form.links.map(item => item.code).join('##') + '#'
            : null,
        };

        if (this.parents) {
          data.parents = this.parents;
        }

        await this.docRoutePointAddAction(data);

        this.refreshPartlyPreviewAction(DOC_GANTT_ITEMS);

        this.$eventBus.$emit('table-trigger-refresh-only-state');

        if (this.closeForm) {
          this.hide();

          return;
        }

        if (this.showRunAfter) {
          this.clearAfterRunRoutePointAction();
          await this.fetchAfterRunRoutePointAction(this.fetchDataAfterRun);
          this.form.links = null;
        }

        this.$notify({
          title: 'Точка маршрута успешно добавлена',
          type: SUCCESS_NOTIFY_TYPE,
        });

      } catch (e) {
        this.$notify({
          title: 'Ошибка добавления',
          type: ERROR_NOTIFY_TYPE,
          text: e?.error_message || 'Неизвестная ошибка',
        });
      } finally {
        this.hideLoader();
      }
    },
    copyContent() {
      let content = fullTrim(this.doc['Содержание'] || '');
      if (content.length > 100) {
        content = content.slice(0, 97) + '...';
      }

      this.form.content = content;
    },
    copyEndDate() {
      if (this.doc['Срок']) {
        this.form.end_date = DateTime.fromFormat(this.doc['Срок'], 'yyyy-MM-dd HH:mm:ss')
          .toFormat('yyyy-MM-dd');
      }
    },
    copyComment() {
      let comment = fullTrim(this.doc['Комментарий'] || '');
      if (comment.length > 250) {
        comment = comment.slice(0, 247) + '...';
      }

      this.form.comment = comment;
    },
  },
  watch: {
    keyDataAfterRun: {
      handler() {
        this.fetchAfterRunRoutePointAction(this.fetchDataAfterRun);
        this.$set(this.form, 'links', []);
      },
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors";

.info {
  padding-top: 10px;
  margin-bottom: 24px;

  color: $blue-black-color;
  font-size: 14px;
}

.checkbox-form {
  margin-right: auto !important;
}

/* TODO: Отказаться от перекрытия стилей */
:deep(.checkmark) {
  margin-top: -3px;
}
</style>
