import HttpService from '@/services/http';
import {AUTO_REPLACES_GET} from "@/configs/endPoints";
import {COMMENT_AUTOCORRECT_COUNT, TEMPLATE_AUTOCORRECT_COUNT, USER_AUTOCORRECT_COUNT} from "@/configs/autocorrect";

export default {
  actions: {
    addUserAutocorrect(ctx, user_id) {
      ctx.commit('autocorrectAddUserMutations', user_id);
    },
    delUserAutocorrect(ctx, user_id) {
      ctx.commit('autocorrectDelUserMutations', user_id);
    },
    addReplaceAutocorrect(ctx, fav_id) {
      ctx.commit('autocorrectAddReplaceMutations', fav_id);
    },
    delReplaceAutocorrect(ctx, fav_id) {
      ctx.commit('autocorrectDelReplaceMutations', fav_id);
    },
    addCommentAutocorrect(ctx, comment) {
      ctx.commit('autocorrectAddCommentMutations', comment);
    },
    delCommentAutocorrect(ctx, comment) {
      ctx.commit('autocorrectDelCommentMutations', comment);
    },
    async setAutocorrectReplacesSelectAction(ctx, data) {
      if (!ctx.state.autocorrect_replaces_select?.length) {
        return await HttpService.post(AUTO_REPLACES_GET, data).then((r) => {
          ctx.commit('autocorrectReplacesSelectMutations', r);
        });
      }
    },
  },
  mutations: {
    autocorrectCleanMutations(state) {
      state.users_autocorrect = [];
      state.comment_autocorrect = [];
      state.autocorrect_replaces = [];
      state.autocorrect_replaces_select = [];
    },
    autocorrectAddUserMutations(state, user_id) {
      user_id = Number(user_id);
      if (user_id > 0 && !state.users_autocorrect.includes(user_id)) {
        state.users_autocorrect.unshift(user_id);
        state.users_autocorrect = state.users_autocorrect.slice(0, USER_AUTOCORRECT_COUNT)
      }
    },
    autocorrectAddCommentMutations(state, comment) {
      state.comment_autocorrect = state.comment_autocorrect.filter(c => comment !== c);
      state.comment_autocorrect.unshift(comment);
      state.comment_autocorrect = state.comment_autocorrect.slice(0, COMMENT_AUTOCORRECT_COUNT);
    },
    autocorrectDelUserMutations(state, user_id) {
      user_id = Number(user_id);
      state.users_autocorrect = state.users_autocorrect.filter(v => v !== user_id);
    },
    autocorrectDelReplaceMutations(state, fav_id) {
      fav_id = Number(fav_id);
      state.autocorrect_replaces = state.autocorrect_replaces.filter(v => v !== fav_id);
    },
    autocorrectDelCommentMutations(state, comment) {
      state.comment_autocorrect = state.comment_autocorrect.filter(v => v !== comment);
    },
    autocorrectAddReplaceMutations(state, fav_id) {
      fav_id = Number(fav_id);
      if (fav_id > 0 && !state.autocorrect_replaces.includes(fav_id)) {
        state.autocorrect_replaces.unshift(fav_id);
        state.autocorrect_replaces = state.autocorrect_replaces.slice(0, TEMPLATE_AUTOCORRECT_COUNT)
      }
    },
    autocorrectReplacesSelectMutations(state, data) {
      state.autocorrect_replaces_select = data.data.items
    },
  },
  state: {
    users_autocorrect: [],
    comment_autocorrect: [],
    autocorrect_replaces: [],
    autocorrect_replaces_select: [],
  },
  getters: {
    usersAutocorrectGetter(state) {
      return state.users_autocorrect;
    },
    coomentAutocorrectGetter(state) {
      return [...state.comment_autocorrect].reverse();
    },
    autocorrectReplaceSelectGetter(state) {
      let select = []
      for (let n in state.autocorrect_replaces_select) {
        select.push({
          label: state.autocorrect_replaces_select[n]['Замена'],
          code: state.autocorrect_replaces_select[n]['FAV_ID'],
        })
      }
      return select;
    },
    autocorrectReplaceGetter(state) {
      return state.autocorrect_replaces;
    },
    autocorrectReplaceObjListGetter(state) {
      let obg = {}
      for (let n in state.autocorrect_replaces_select) {
        obg[state.autocorrect_replaces_select[n]['FAV_ID']] = state.autocorrect_replaces_select[n]
      }
      return obg;
    },
  },
}
