<template>
  <header class="header">
    <div class="side side_left">
      <div class="wrapper" @click="toggle">
        <chevron-icon class="chevron" :class="{_open: isOpen}"/>

        <span class="title">{{ title }}</span>

        <badge-ui :value="amount"></badge-ui>
      </div>

      <slot name="after-title"/>
    </div>

    <div v-if="$slots.default || showAdd" class="side">
      <slot/>
      <add-gray-button v-if="showAdd" :no-title="noButtonTitle" @click="$emit('add')"/>
    </div>

    <resize-observer ref="resize" @notify="onResize"/>
  </header>
</template>

<script>
import ChevronIcon from "@/assets/svg/chevron.svg?component";
import AddGrayButton from "@/components/buttons/AddGrayButton.vue";
import BadgeUi from '@/components/ui/BadgeUi.vue';

export default {
  name: "PreviewBlockHeader",
  components: {BadgeUi, AddGrayButton, ChevronIcon},
  props: {
    title: {
      type: String,
      required: true,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['toggle', 'add'],
  data() {
    return {
      isOpen: true,
      width: 0,
    }
  },
  mounted() {
    this.width = this.$refs.resize.$el.clientWidth;
  },
  computed: {
    noButtonTitle() {
      return this.width < 400;
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle', this.isOpen);
    },
    onResize({width}) {
      this.width = width;
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors";

.header {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.side {
  display: flex;
  align-items: center;
  color: $blue-black-color;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &_left {
    min-width: 0;
  }
}

.wrapper {
  padding: 8px;
  margin: -8px 0 -8px -8px;

  display: flex;
  align-items: center;

  cursor: pointer;
}

.chevron {
  margin-right: 8px;
  fill: $blue-light-color;

  transition: transform 0.3s ease-in-out;

  &:not(._open) {
    transform: rotate(-90deg);
  }
}

.title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: $blue-black-color;

  &:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
