<template>
  <div class="spinner-ui" :class="`_size-${size}`"></div>
</template>

<script>
import {defineComponent} from 'vue';
import {only} from '@/utils/propsValidators';

export default defineComponent({
  name: 'SpinnerUi',
  props: {
    size: {
      type: String,
      default: 'm',
      validator: only('s', 'm', 'l'),
    },
  },
});
</script>

<style scoped lang="scss">
.spinner-ui {
  background-image: url('@/assets/svg/spinner.svg');
  background-size: 400%;
  animation: spinner 4s infinite step-end;

  &._size-s {
    width: 14px;
    height: 14px;
  }

  &._size-m {
    width: 20px;
    height: 20px;
  }

  &._size-l {
    width: 36px;
    height: 36px;
  }
}

$step-duration: 100% / 12;
$step-h: 100% / 3;
$step-v: 100% / 2;

@keyframes spinner {
  0% {
    background-position: 0 0;
  }
  #{$step-duration} {
    background-position: $step-h 0;
  }
  #{$step-duration * 2} {
    background-position: ($step-h * 2) 0;
  }
  #{$step-duration * 3} {
    background-position: ($step-h * 3) 0;
  }
  #{$step-duration * 4} {
    background-position: 0 $step-v;
  }
  #{$step-duration * 5} {
    background-position: $step-h $step-v;
  }
  #{$step-duration * 6} {
    background-position: ($step-h * 2) $step-v;
  }
  #{$step-duration * 7} {
    background-position: ($step-h * 3) $step-v;
  }
  #{$step-duration * 8} {
    background-position: 0 ($step-v * 2);
  }
  #{$step-duration * 9} {
    background-position: $step-h ($step-v * 2);
  }
  #{$step-duration * 10} {
    background-position: ($step-h * 2) ($step-v * 2);
  }
  #{$step-duration * 11} {
    background-position: ($step-h * 3) ($step-v * 2);
  }
}
</style>
