<template>
  <header class="master-modal-container__header" :class="classesHeader">
    <h2 class="master-modal-container__title">{{ titleModal }}</h2>
    <div
        aria-label="Этот мастер поможет выполнить ввод данных. Для начала укажите тип и  проект, к которому следует отнести документ, и нажмите на кнопку «Далее»"
        data-tooltip-position="bottom-left"
        data-tooltip-size="auto"
        role="tooltip">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#F3F6FB"/>
        <path
            d="M12 18.75L12 11.4271C12 10.3992 11.4193 9.45965 10.5 9V9M10.5 18.75L13.5 18.75"
            stroke="#5F6A7D"
            stroke-width="1.5"
            stroke-linecap="round"
        />
        <circle cx="12" cy="6" r="1.5" fill="#5F6A7D"/>
      </svg>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderMaster",
  props: ['classesHeader', 'titleModal']
}
</script>