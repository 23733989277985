<template>
  <div>
    <li ref="tabUiTitle"
        :id="idTubTitle"
        @click="setActive"
        class="nav-item">
      <a class="tabs-ui__nav-link"
         :class="{active: isActive}"
         href="#">
        <slot name="title"></slot>
      </a>
    </li>
    <transition name="tabs-ui">
      <div class="tab-pane"
           v-if="isRender"
           v-show="isActive"
           :id="idTubContent">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import {randomKey} from "@/services/utilsFunctions";

export default {
  name: "TabUi",
  inject: {
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const id = randomKey('');
    return {
      idTubContent: 'tab-content-' + id,
      idTubTitle: 'tab-title-' + id,
      isActive: this.active,
      isRendered: this.active,
    };
  },
  computed: {
    isRender() {
      return this.isActive || this.isRendered || !this.lazy;
    },
  },
  methods: {
    setActive() {
      this.isActive = true;
      this.isRendered = true;
      this.$parent
          .$children
          .filter(children => children.$options.name === 'TabUi' && children.idTubContent !== this.idTubContent)
          .forEach(children => children.clearActive());
    },
    clearActive() {
      this.isActive = false;
    },
  },
};
</script>