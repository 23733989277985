<template>
  <div class="gantt-document-preview">
    <add-route-point-modal v-if="showAdd" ref="addModal" :doc-id="doc_id"/>

    <preview-block-header title="Маршрут" :show-add="showAdd" :amount="tasks.length" @toggle="toggle" @add="add">
      <button class="button" type="button" @click="ganttZoomIn">
        <zoom-in-icon class="button-icon" />
      </button>
      <button class="button" type="button" @click="ganttZoomDefault">
        <distribute-spacing-horizontal-icon class="button-icon"/>
      </button>
      <button class="button" type="button" @click="ganttZoomOut">
        <zoom-out-icon class="button-icon"/>
      </button>
      <button class="button button_last" type="button" @click="ganttRecenterPosition">
        <today-icon class="button-icon"/>
      </button>
    </preview-block-header>

    <transition name="document-sheet">
      <div v-if="showContent" class="gantt-document-preview--content pt-3 pb-2">
        <gantt
            :doc="doc"
            :tasks="tasks"
            :key="ganttKey"
            :rp_id="rp_id"
            ref="ganttBlock"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import PreviewBlockHeader from "@/components/doc/preview/PreviewBlockHeader.vue";
import Gantt from "@/components/doc/gantt/GanttBlock";
import ZoomInIcon from "@/components/icons/ZoomInIcon";
import DistributeSpacingHorizontalIcon from "@/components/icons/DistributeSpacingHorizontalIcon";
import ZoomOutIcon from "@/components/icons/ZoomOutIcon";
import TodayIcon from "@/components/icons/TodayIcon";
import AddRoutePointModal from "@/components/modals/RoutePoint/AddRoutePointModal";

export default {
  name: "GanttDocumentPreview",
  components: {
    AddRoutePointModal,
    TodayIcon,
    ZoomOutIcon,
    DistributeSpacingHorizontalIcon,
    ZoomInIcon,
    PreviewBlockHeader,
    Gantt,
  },
  props: [
    'tasks',
    'doc_id',
    'doc',
    'ready',
    'showAdd',
    'rp_id',
  ],
  data() {
    return {
      showContent: true,
    };
  },
  computed: {
    ganttKey() {
      return JSON.stringify(this.tasks);
    },
  },
  methods: {
    toggle(show) {
      this.showContent = show;
    },
    add() {
      this.$refs.addModal.show();
    },
    ganttZoomIn() {
      this.$refs.ganttBlock.ganttZoomIn();
    },
    ganttZoomDefault() {
      this.$refs.ganttBlock.ganttZoomDefault();
    },
    ganttZoomOut() {
      this.$refs.ganttBlock.ganttZoomOut();
    },
    ganttRecenterPosition() {
      this.$refs.ganttBlock.ganttRecenterPosition();
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors";

.button {
  margin: -5px 0;
  padding: 5px;

  &:hover {
    .button-icon {
      fill: $blue-black-color;
    }
  }

  &_last {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.button-icon {
  fill: $blue-light-color;
  transition: fill 0.3s ease-in-out;
}
</style>
