export default {
    inserted: (element, {value}) => {
        element.style.transition = 'transform var(--transition)';

        if (value !== false) {
            element.style.transform = 'rotate(180deg)';
        }
    },
    update(element, {value}) {
        element.style.transform = value !== false ? 'rotate(180deg)' : null;
    }
};
