<template>
  <button class="btn btn-add-grey" v-bind="$attrs" v-on="$listeners" type="button">
    <edit-icon class="button-icon"/>
    <span v-if="!noTitle">Редактировать</span>
  </button>
</template>

<script>
import EditIcon from "@/components/icons/EditIcon";

export default {
  name: "EditButton",
  props: {
    noTitle: {
      type: Boolean,
      default: false,
    }
  },
  components: {EditIcon},
}
</script>
