<template>
  <preview-block-header ref="headerRef" :class="{_open: isOpen}" title="Просмотр" @toggle="toggle">
    <template #after-title>
      <file-truncated-name :file="file" is-muted/>
    </template>

    <div v-if="showPagination && isOpen && pagesCount > 1" class="pagination">
      <button class="button" :disabled="currentPage === 1" @click="$emit('prev')">
        <collapse-icon class="button-icon"/>
      </button>

      <button class="button" :disabled="currentPage === pagesCount" @click="$emit('next')">
        <chevron-right-icon class="button-icon"/>
      </button>
    </div>

    <edit-button v-if="showEdit" :no-title="noButtonTitle" @click="openEditModal"/>

    <edit-file-modal
      v-if="showEdit"
      ref="editFileModal"
      :file="file"
      :pages-count="pagesCount"
      :initial-page="currentPage"
    />
  </preview-block-header>
</template>

<script>
import CollapseIcon from "@/components/icons/CollapseIcon";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon";
import PreviewBlockHeader from '@/components/doc/preview/PreviewBlockHeader.vue';
import EditFileModal from '@/components/modals/File/EditFileModal.vue';
import EditButton from '@/components/buttons/EditButton.vue';
import {defineComponent} from 'vue';
import FileTruncatedName from '@/components/doc/files/FileTruncatedName.vue';

export default defineComponent({
  name: "FilePreviewHeader",
  components: {
    FileTruncatedName,
    PreviewBlockHeader,
    ChevronRightIcon,
    CollapseIcon,
    EditFileModal,
    EditButton,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    pagesCount: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
  },
  emits: ['toggle', 'prev', 'next'],
  data() {
    return {
      isOpen: true,
      showEditModal: false,
    };
  },
  computed: {
    noButtonTitle() {
      return this.$refs.headerRef?.width < 432;
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle', this.isOpen);
    },
    openEditModal() {
      this.$refs.editFileModal.show();
    },
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors";

.header {
  padding: 16px;
  background-color: white;
  border-radius: 8px;

  &:not(:last-child) {
    margin: 0;
  }

  &._open {
    border-radius: 8px 8px 0 0;
  }
}

.file-truncated-name {
  transform: translateY(1.5px);
}

.pagination {
  display: flex;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.button {
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity var(--transition);

  &:hover {
    .button-icon {
      fill: $blue-black-color;
    }
  }

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.button-icon {
  fill: $blue-light-color;
  transition: fill 0.3s ease-in-out;
}
</style>
