<template>
  <div class="file-name-button">
    <spinner-ui v-if="$refs.nameRef?.disabled"/>
    <component v-else class="file-icon" :is="fileIcon"/>
    <file-signature-icon v-if="withSign && fileSign" :count="fileSign"/>
    <file-truncated-name ref="nameRef" :file="file"/>
  </div>
</template>

<script>
import viewFile from '@/mixins/viewFile';
import FileTruncatedName from '@/components/doc/files/FileTruncatedName.vue';
import FileSignatureIcon from '@/components/icons/FileSignatureIcon.vue';
import {defineComponent} from 'vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';

export default defineComponent({
  name: "FileNameButton",
  components: {SpinnerUi, FileSignatureIcon, FileTruncatedName},
  props: {
    file: {
      type: Object,
      required: true,
    },
    withSign: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [viewFile],
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors";

.file-name-button {
  min-width: 0;

  display: flex;
  align-items: center;
}

.spinner-ui {
  flex-shrink: 0;
  margin-right: 8px;
}

.file-icon {
  flex-shrink: 0;
  margin-right: 8px;

  /* FIXME: Отказаться от перекрытия стилей */
  fill: $blue-light-color;
}
</style>
