<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#E7EBF7"/>
    <path d="M8 12.5L8 7.61803C8 6.93283 7.61287 6.30643 7 6V6M7 12.5L9 12.5" stroke="#5F6A7D" stroke-linecap="round"/>
    <circle cx="8" cy="4" r="1" fill="#5F6A7D"/>
  </svg>
</template>

<script>
export default {
  name: "InfoItemArrayForm"
}
</script>

