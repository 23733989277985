<template>
  <modal-ui ref="modal" title="Поставить на паузу" popup>
    <template #body>
      Вы собираетесь поставить документ на паузу. Продолжить?
    </template>

    <template #footer>
      <close-button @click="hide"/>

      <stop-doc-button @click="apply">
        <template #title>На паузу</template>
        <template #icon>
          <pause-icon/>
        </template>
      </stop-doc-button>
    </template>
  </modal-ui>
</template>

<script>
import {mapActions} from "vuex";
import CloseButton from "@/components/buttons/CloseButton";
import StopDocButton from "@/components/buttons/StopDocButton";
import VuexAdapter from "@/services/vuexAdapter";
import {DOC_ACTIVATION_SET, DOC_GANTT_ITEMS, DOCS_GET} from "@/configs/endPoints";
import PauseIcon from "@/components/icons/PauseIcon.vue";
import {ERROR_NOTIFY_TYPE} from "@/configs/notifyTypes";
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';

export default {
  name: "PauseDocModal",
  components: {ModalUi, PauseIcon, StopDocButton, CloseButton},
  mixins: [modal],
  props: {
    docId: {
      type: Number,
      required: true,
    },
    events: {
      type: Array,
    },
  },
  methods: {
    ...mapActions({
      controlsDocActivationAction: VuexAdapter.getNameAction(DOC_ACTIVATION_SET),
      refreshPartlyPreview: 'refreshPartlyPreviewAction',
    }),
    apply() {
      this.showLoader();

      this.controlsDocActivationAction({
        doc_id: this.docId,
        active: 0
      }).then(() => {
        this.throwEvents();
      }).catch(e => {
        this.$notify({
          title: 'Ошибка: не удалось поставить документ на паузу',
          type: ERROR_NOTIFY_TYPE,
          text: e?.error_message || 'Неизвестная ошибка',
        });
        this.hideLoader();
      });
    },
    throwEvents() {
      this.events.forEach((value) => {
        this.$eventBus.$emit(value);
      });
      this.refreshPartlyPreview(DOCS_GET);
      this.refreshPartlyPreview(DOC_GANTT_ITEMS);
      this.hide();
    },
  }
}
</script>
