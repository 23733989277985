<template>
  <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.80474 0.195262C6.06509 0.455612 6.06509 0.877722 5.80474 1.13807L2.80474 4.13807C2.54439 4.39842 2.12228 4.39842 1.86193 4.13807L0.528598 2.80474C0.268248 2.54439 0.268248 2.12228 0.528598 1.86193C0.788948 1.60158 1.21106 1.60158 1.47141 1.86193L2.33334 2.72386L4.86193 0.195262C5.12228 -0.0650874 5.54439 -0.0650874 5.80474 0.195262Z"/>
  </svg>
</template>

<script>
export default {
  name: "StrokeIcon"
}
</script>

<style scoped>

</style>