import Vue from 'vue';
import {BootstrapVue} from 'bootstrap-vue'
import router from './routes';
import store from './store';
import PortalVue from 'portal-vue'
import App from './App.vue';
import VueMeta from 'vue-meta'
import TableIndex from "@/components/table/TableIndex";
import DateRangePicker from 'vue2-daterange-picker';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import fontAwesomeDynamicIcon from '@/components/Icon.vue';
import VueCircleProgress from 'vue2-circle-progress';
import VuexAdapter from "@/services/vuexAdapter";
import noSelect from "@/services/noSelect";
import Notifications from 'vue-notification';
import * as Sentry from "@sentry/vue";
import touchEvents from '@alphadoc/alphadoc-touch-events';
import {ExceptionService} from '@/services/exception/exceptionService';
import VueResize from 'vue-resize';

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({Vue, dsn: process.env.VUE_APP_SENTRY_DSN});
}

ExceptionService.init();

Vue.use(BootstrapVue);

//Глобальная шина событий
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$vuexAdapter = VuexAdapter;

Vue.component('DateRangePicker', DateRangePicker);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-dynamic-icon', fontAwesomeDynamicIcon);
Vue.component('TableIndex', TableIndex);
Vue.component('vue-circle-progress', VueCircleProgress);
Vue.directive('no-select', noSelect);

Vue.config.productionTip = false;

require('@/assets/scss/main.scss');
require('vue2-daterange-picker/dist/vue2-daterange-picker.css');

Vue.use(BootstrapVue);
Vue.use(VueMeta);
Vue.use(PortalVue);
Vue.use(touchEvents, {
  disableClick: false
});
Vue.use(Notifications);
Vue.use(VueResize);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
