import {DOC_ROUTESPOINTS_DIRECTORY_GET} from "@/configs/endPoints";
import HttpService from '@/services/http';
import routePointStatuses from "@/configs/routePointStatuses";

export default {
  actions: {
    async fetchAfterRunRoutePointAction(ctx, {doc_id, rp_id = 0, termFromInDays = false, date = null}) {

      if (!termFromInDays && !date) {
        return;
      }

      const statuses = (!rp_id ? [
        routePointStatuses.rp_status_type_approved_id,
        routePointStatuses.rp_status_type_rejected_id,
        routePointStatuses.rp_status_type_completed_id,
        routePointStatuses.rp_status_type_completion_confirmed_id,
        routePointStatuses.rp_status_type_stopped_id,
        routePointStatuses.rp_status_type_accepted_id,
      ] : [
        routePointStatuses.rp_status_type_stopped_id,
      ]).join(',');

      let where = `DOC_ID = ${doc_id} AND RPS_ID NOT IN (${statuses}) AND RP_ID <> ${rp_id} AND `;

      if (ctx.getters['server/isMssql']) {
        if (termFromInDays) {
          where += `dbo.CountLevel(PARENTS) = 1  AND (LINKS NOT LIKE '%%#${rp_id}#%%' OR LINKS IS NULL) `;
        } else {
          where += `[Дата] < '${date}' AND dbo.CountLevel(PARENTS) = 1 `;
        }
      } else {
        if (termFromInDays) {
          where += `CountLevel(PARENTS) = 1 AND (LINKS NOT ILIKE '%%#${rp_id}#%%' OR LINKS IS NULL) `;
        } else {
          where += ` "Дата" < '${date}' AND CountLevel(PARENTS) = 1 `;
        }
      }

      ctx.state.loadingAfterRunRoutePoint = true;

      return HttpService.post(DOC_ROUTESPOINTS_DIRECTORY_GET, {doc_id, where}).then((r) => {
        ctx.state.listAfterRunRoutePoint = [
          ...r.data.items
        ];
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        ctx.state.loadingAfterRunRoutePoint = false;
      });
    },
    clearAfterRunRoutePointAction(ctx) {
      ctx.state.loadingAfterRunRoutePoint = true;
      ctx.state.listAfterRunRoutePoint = [];
    },
  },
  state: {
    loadingAfterRunRoutePoint: true,
    listAfterRunRoutePoint: [],
  },
  getters: {
    selectedOptionsAfterRunRoutePointGetter(state) {
      return state.listAfterRunRoutePoint.map(item => {
        return {
          code: item.RP_ID,
          label: item['Номер'] + '. ' + item['Исполнитель'] + ' ' + item['Описание'],
        }
      })
    },
  },
}
