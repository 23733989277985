<template>
    <div class="ratio-a4 ratio-a4-content d-inline-flex justify-content-center align-items-center w-100"
         :style="'background-image: url(' + url + ');'">
    </div>
</template>

<script>
export default {
    name: 'FileImgPagePreview',
    props: {
        url: {
            type: String,
            required: true
        },
    }
}
</script>