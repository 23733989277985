<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1567_833)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99996 1.99996C4.68625 1.99996 1.99996 4.68625 1.99996 7.99996C1.99996 11.3137 4.68625 14 7.99996 14C8.36815 14 8.66663 14.2984 8.66663 14.6666C8.66663 15.0348 8.36815 15.3333 7.99996 15.3333C3.94987 15.3333 0.666626 12.05 0.666626 7.99996C0.666626 3.94987 3.94987 0.666626 7.99996 0.666626C8.36815 0.666626 8.66663 0.965103 8.66663 1.33329C8.66663 1.70148 8.36815 1.99996 7.99996 1.99996Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 1.99996C11.3137 1.99996 14 4.68625 14 7.99996C14 11.3137 11.3137 14 8.00004 14C7.63185 14 7.33337 14.2984 7.33337 14.6666C7.33337 15.0348 7.63185 15.3333 8.00004 15.3333C12.0501 15.3333 15.3334 12.05 15.3334 7.99996C15.3334 3.94987 12.0501 0.666626 8.00004 0.666626C7.63185 0.666626 7.33337 0.965103 7.33337 1.33329C7.33337 1.70148 7.63185 1.99996 8.00004 1.99996Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.4967 4.95541C7.503 4.95946 7.50928 4.9635 7.51554 4.96752L9.08907 5.97908C9.39879 6.17818 9.48845 6.59066 9.28935 6.90037C9.09025 7.21008 8.67778 7.29975 8.36806 7.10065L6.99997 6.22116V9.82322C7.26782 9.75917 7.55846 9.8665 7.71582 10.1113C7.91492 10.421 7.82526 10.8335 7.51554 11.0326C7.50928 11.0366 7.50299 11.0406 7.49669 11.0447C7.37954 11.12 7.25637 11.1993 7.14789 11.2557C7.03875 11.3125 6.84071 11.4045 6.59539 11.387C6.30606 11.3663 6.03996 11.221 5.86612 10.9888C5.71872 10.7919 5.68896 10.5756 5.67773 10.4531C5.66656 10.3313 5.6666 10.1849 5.66663 10.0456C5.66663 10.0381 5.66663 10.0306 5.66663 10.0232V5.97694C5.66663 5.96949 5.66663 5.96202 5.66663 5.95453C5.6666 5.81524 5.66656 5.66879 5.67773 5.547C5.68896 5.42448 5.71872 5.20817 5.86612 5.01129C6.03996 4.77909 6.30606 4.63381 6.59539 4.61315C6.84071 4.59562 7.03875 4.68756 7.14789 4.74437C7.25637 4.80083 7.37955 4.88006 7.4967 4.95541Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.59424 5.16769C6.79334 4.85798 7.20582 4.76831 7.51553 4.96741L10.6626 6.99052C10.6683 6.99422 10.6741 6.99794 10.68 7.00168C10.7807 7.06637 10.8907 7.13707 10.9784 7.20545C11.0714 7.27797 11.2181 7.40588 11.3047 7.61072C11.4098 7.85955 11.4098 8.14033 11.3047 8.38916C11.2181 8.594 11.0714 8.72191 10.9784 8.79443C10.8907 8.8628 10.7807 8.9335 10.68 8.9982C10.6741 9.00194 10.6683 9.00566 10.6626 9.00935L7.51553 11.0325C7.20582 11.2316 6.79335 11.1419 6.59424 10.8322C6.39514 10.5225 6.48481 10.11 6.79452 9.91089L9.76712 7.99994L6.79452 6.08898C6.48481 5.88988 6.39514 5.47741 6.59424 5.16769Z"/>
    </g>
    <defs>
      <clipPath id="clip0_1567_833">
        <rect width="16" height="16" fill="none"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "PlayCircleIcon"
}
</script>

<style scoped>

</style>