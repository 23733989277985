<template>
    <span class="table-badge badge-red">{{value}}</span>
</template>

<script>
    export default {
        name: 'BadgeDanger',
        props: ['value'],
    };
</script>

<style lang="css">

</style>