<template>
  <div class="files-document-preview">
    <upload-file-modal ref="addModal" :doc-id="doc_id"/>

    <preview-block-header title="Вложения" :show-add="showAdd" :amount="filesLast.length" @toggle="toggle" @add="add"/>

    <transition name="document-sheet">
      <div v-if="showContent" class="files-document-preview--content">
        <div v-if="ready && files.length && !error">
          <div v-for="file in filesView"
               :key.prop="file"
               class="mt-3">
            <div class="d-flex">
              <div class="black-text-bold">{{ file['Пользователь'] }}</div>
              <div class="grey-text ml-2">{{ getDate(file['Создан'], 'dd.MM.yyyy, HH:mm') }}</div>
            </div>

            <div>
              <div class="d-flex align-items-center justify-content-start mt-1">
                <file-name-button v-if="file['Файл']" :file="file" with-sign/>

                <div class="file-redaction-button"
                     v-if="file['Редакция'] > 1"
                     @click.prevent="filesPrevRevisionActive = filesPrevRevisionActive === file['Номер'] ? false : file['Номер']">
                  {{ file['Редакция'] }}-aя редакция
                </div>

                <file-note v-if="file['Примечание']" :note="file['Примечание']"/>
              </div>

              <div v-if="file['Редакция'] > 1 && filesPrevRevisionActive === file['Номер']"
                   class="pl-3 mt-2 prev-files-block">
                <div v-for="prevFile in getFilesPrevRevision(file['Название'])"
                     :key.prop="prevFile">
                  <div class="d-flex mt-1">
                    <div class="black-text-bold">{{ prevFile['Пользователь'] }}</div>
                    <div class="grey-text ml-2">{{ getDate(prevFile['Создан'], 'dd.MM.yyyy, HH:mm') }}</div>
                  </div>

                  <div class="d-flex align-items-center justify-content-start mt-1">
                    <file-name-button v-if="prevFile['Файл']" :file="prevFile" with-sign/>

                    <div class="file-redaction"
                         v-if="prevFile['Редакция'] > 1">
                      {{ prevFile['Редакция'] }}-aя редакция
                    </div>

                    <file-note v-if="prevFile['Примечание']" :note="prevFile['Примечание']" sub/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uploader-block-footer">
            <div v-if="loader"
                 class="progress-bar progress-bar-striped progress-bar-animated bg-warning w-100"
                 aria-valuemin="0" aria-valuemax="100" style="height: 5px"
                 role="progressbar"></div>
            <show-more-button v-if="showMoreButton"
                              v-on:click="load"/>
          </div>
        </div>
        <div v-else-if="error" class="grey-text mt-3 mb-3">
          Ошибка получения данных
        </div>
        <div v-else class="grey-text mt-3 mb-3">
          Список вложений пуст
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PreviewBlockHeader from "@/components/doc/preview/PreviewBlockHeader.vue";
import {getDate} from "@/services/utilsFunctions";
import ShowMoreButton from "@/components/buttons/ShowMoreButton";
import {DOC_FILES_GET} from "@/configs/endPoints";
import FileNote from "@/components/doc/files/FileNote";
import UploadFileModal from "@/components/modals/File/UploadFileModal";
import {ERROR_NOTIFY_TYPE} from "@/configs/notifyTypes";
import FileNameButton from '@/components/doc/files/FileNameButton.vue';
import {ErrorHelper} from '@/services/errorHelper';

export default {
  name: "FilesDocumentPreview",
  components: {FileNameButton, UploadFileModal, FileNote, ShowMoreButton, PreviewBlockHeader},
  props: ['doc_id', 'ready', 'files', 'showAdd'],
  data: () => ({
    filesPrevRevisionActive: false,
    loader: false,
    limit: 5,
    views: 0,
    viewsAll: 5,
    filesView: [],
    showContent: true,
  }),
  mounted() {
    this.getFilesLastRevision();
  },
  methods: {
    getDate,
    getFilesPrevRevision(fileName) {
      return this.files
        .filter((file) => {
          return file['Индикатор'] === 0
            && file['Название'] === fileName
        });
    },
    getFilesLastRevision() {
      this.filesLast.forEach((file, key) => {
        if (this.views < this.viewsAll && key === this.views) {
          this.views++;
          this.filesView.push(file);
        }
      })
    },
    load() {
      this.loader = true;
      this.viewsAll = this.viewsAll + this.limit;
      this.getFilesLastRevision();
      this.loader = false;
    },
    add() {
      this.$refs.addModal.show();
    },
    toggle(show) {
      this.showContent = show;
    },
  },
  computed: {
    showMoreButton() {
      return this.filesLast.length && this.filesLast.length > this.viewsAll
    },
    error() {
      return this.$store.getters[this.$vuexAdapter.getNameErrorGetter(DOC_FILES_GET)];
    },
    filesLast() {
      return this.files.filter(file => file['Индикатор'] > 0);
    },
  },
  watch: {
    error(val) {
      if (val) {
        this.$notify({
          title: 'Ошибка получения данных',
          type: ERROR_NOTIFY_TYPE,
          text: ErrorHelper.format(val) || 'Неизвестная ошибка', // TODO: 'Неизвестная ошибка' никогда не отобразится
        });
      }
    },
    ready(value) {
      if (value) {
        this.filesView = [];
        this.views = 0;
        this.getFilesLastRevision();
      }
    },
  }
}
</script>

<style scoped lang="scss">
.file-name-button {
  &:not(:last-child) {
    margin-right: 12px;
  }
}
</style>
