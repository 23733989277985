<template v-if="page_lazy_load">
  <component :is="page" @restartLoad="restartLoad" :url="url"/>
</template>

<script>
import FileLoadingPreview from "@/components/doc/files/FileLoadingPreview";
import FileFailPreview from "@/components/doc/files/FileFailPreview";
import FileImgPagePreview from "@/components/doc/files/FileImgPagePreview";
import axios from "axios";
import {mapState} from "vuex";

const FILE_LOADING_PREVIEW = 'file-loading-preview';
const FILE_FAIL_PREVIEW = 'file-fail-preview';
const FILE_IMG_PAGE_PREVIEW = 'file-img-page-preview';

export default {
  name: "FilePagePreview",
  props: {
    page_number: {
      type: Number,
      required: true
    },
    page_lazy_load: {
      type: Boolean,
      required: true
    },
    file_hash: {
      type: String,
      required: true
    },
  },
  data: () => ({
    url: false,
    page: FILE_LOADING_PREVIEW,
    timeout_page_get: 1500,
    timeout_page_get_limit: 1000 * 60 * 0.3,
    file_preview_page_get_count_request: 0,
    waiting_request: false,
    waiting_request_i: 0,
    waiting_request_i_limit: 60,
    abortController: new AbortController(),
  }),
  components: {
    FileLoadingPreview,
    FileFailPreview,
    FileImgPagePreview
  },
  computed: {
    ...mapState('server', ['storageUrl']),
    classes() {
      return {
        load: [FILE_LOADING_PREVIEW, FILE_FAIL_PREVIEW].includes(this.page)
      }
    },
  },
  created() {
    if (this.page_lazy_load) {
      this.load();
    }
  },
  methods: {
    getPageUrl(i) {
      let zero = '';
      if (i < 10) {
        zero = '000'
      } else if (i < 100) {
        zero = '00'
      } else if (i < 1000) {
        zero = '0'
      }

      return `${this.storageUrl}${this.file_hash}_page${zero}${i}.jpg`;
    },
    restartLoad() {
      this.page = FILE_LOADING_PREVIEW;
      this.waiting_request_i = 0;
      this.file_preview_page_get_count_request = 0;
      this.load();
    },
    load() {
      return new Promise(resolve => {

        const f = () => {

          if (this.waiting_request) {
            this.waiting_request_i++;
            if (this.waiting_request_i > this.waiting_request_i_limit) {
              this.page = FILE_FAIL_PREVIEW;
              if (this?.file_preview_page_get_interval) {
                clearInterval(this.file_preview_page_get_interval);
              }
              resolve();
            }
            return;
          }

          this.waiting_request = true;

          let url = this.getPageUrl(this.page_number);
          axios.get(url, {
            signal: this.abortController.signal
          }).then(() => {
            this.url = url;
            this.page = FILE_IMG_PAGE_PREVIEW;
            if (this?.file_preview_page_get_interval) {
              clearInterval(this.file_preview_page_get_interval);
            }
            resolve();
          }).catch(() => {
            this.file_preview_page_get_count_request++;
            if ((this.file_preview_page_get_count_request * this.timeout_page_get) >= this.timeout_page_get_limit) {
              this.page = FILE_FAIL_PREVIEW;
              if (this?.file_preview_page_get_interval) {
                clearInterval(this.file_preview_page_get_interval);
              }
              resolve();
            }
          }).finally(() => {
            this.waiting_request = false;
          });
        }

        f();

        this.file_preview_page_get_interval = setInterval(f, this.timeout_page_get);
      });
    }
  },
  beforeDestroy() {
    if (this?.file_preview_page_get_interval) {
      clearInterval(this.file_preview_page_get_interval);
    }
    this.abortController.abort();
  },
  watch: {
    page_lazy_load(val) {
      if (val) {
        this.load();
      }
    },
  }
}
</script>
