<template>
  <div class="document-sheet--resolution">
    <add-comment-modal ref="addModal" :parent-id="doc_id" :parent-table-id="parentTableId"/>

    <preview-block-header title="Резолюции" :show-add="showAdd" :amount="amount" @toggle="toggle" @add="add"/>

    <transition name="document-sheet">
      <uploader-block-document-preview
          v-if="showContent"
          :end-point="endPoint"
          :doc-id="doc_id"
          :component="() => import('@/components/doc/preview/rows/CommentRowDocumentPreview.vue')"
      >
        <template v-slot:list-is-empty>Список резолюций пуст</template>
      </uploader-block-document-preview>
    </transition>
  </div>
</template>

<script>
import PreviewBlockHeader from "@/components/doc/preview/PreviewBlockHeader.vue";
import UploaderBlockDocumentPreview from "@/components/doc/preview/UploaderBlockDocumentPreview.vue";
import {DOC_COMMENTS_GET,} from "@/configs/endPoints";
import AddCommentModal from "@/components/modals/Comment/AddCommentModal";
import {DOCS_TABLE} from "@/configs/dbTables";

export default {
  name: "CommentsDocumentPreview",
  components: {AddCommentModal, UploaderBlockDocumentPreview, PreviewBlockHeader},
  props: {
    doc_id: {
      type: Number,
      required: true,
    },
    showAdd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showContent: true,
    };
  },
  computed: {
    parentTableId() {
      return DOCS_TABLE;
    },
    endPoint() {
      return DOC_COMMENTS_GET;
    },
    amount() {
      const getterName = this.$vuexAdapter.getNameCountGetter(this.endPoint);
      return this.$store.getters[getterName];
    },
  },
  methods: {
    toggle(show) {
      this.showContent = show;
    },
    add() {
      this.$refs.addModal.show();
    },
  },
}
</script>
