export default [
  'jpg',
  'jpeg',
  'doc',
  'docx',
  'docm',
  'pdf',
  'rtf',
  'odt',
  'ods',
];
