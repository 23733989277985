import HttpService from '@/services/http';
import {END_POINT_ACTIONS} from "@/configs/endPoints";
import VuexAdapter from "@/services/vuexAdapter";

const actions = {};

END_POINT_ACTIONS.forEach(endPoint => {
  const actionName = VuexAdapter.getNameAction(endPoint);

  actions[actionName] = (ctx, data) => {
    return HttpService.post(endPoint, data);
  }
});

export default {
  actions: {
    ...actions
  },
}
