<template>
  <button class="btn btn btn-back"
          v-bind="$attrs"
          v-on="$listeners"
          type="button">
    <slot name="icon">
      <span/>
    </slot>
    <slot name="title">
      Назад
    </slot>
  </button>
</template>

<script>
export default {
  name: "BackButton",
  inheritAttrs: false,
}
</script>

<style scoped>

</style>