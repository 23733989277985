import HttpService from '@/services/http';
import {SYSTEM_INTERFACE_ELEMENTS_GET, USER_ROLES_GET} from "@/configs/endPoints";
import {EVENTS} from "@/configs/events";
import Vue from "vue";
import {ROLES} from "@/configs/roles";

export default {
  actions: {
    async interfaceElementsGetAction(ctx) {
      return await HttpService.post(SYSTEM_INTERFACE_ELEMENTS_GET).then((r) => {
        ctx.commit('interfaceElementsGetMutations', r);
      });
    },
    async userRolesGetAction(ctx) {
      return await HttpService.post(USER_ROLES_GET).then((r) => {
        ctx.commit('userRolesGetMutations', r);
      });
    },
  },
  mutations: {
    interfaceElementsGetMutations(state, data) {
      state.userEventsIds = [];
      state.userEvents = data.data.items;
      state.userEvents.forEach((value) => {
        state.userEventsIds.push(Number(value.GUIE_ID));
      });
    },
    userRolesGetMutations(state, data) {
      state.userRolesIds = [];
      state.userRoles = data.data.items;
      state.userRoles.forEach((value) => {
        state.userRolesIds.push(Number(value.ROLE_ID));
      });
    },
    clearInterfaceElementsMutations(state) {
      state.userEvents = [];
      state.userEventsIds = [];
      state.userEventsCash = {};

      state.userRoles = [];
      state.userRolesIds = [];
      state.userRolesCash = {};
    },
  },
  state: {
    userEvents: [],
    userEventsIds: [],
    userEventsCash: {},

    userRoles: [],
    userRolesIds: [],
    userRolesCash: {}
  },
  getters: {
    accessToEvent: (state) => (event) => {
      if (state.userEventsCash[event] !== undefined) {
        return state.userEventsCash[event];
      }

      if (EVENTS[event] === undefined) {
        throw new Error(`Not found event: '${event}'`);
      }
      if (!state.userEventsIds.length) {
        return false;
      }

      const eventId = EVENTS[event];

      const access = state.userEventsIds.includes(eventId);

      Vue.set(state.userEventsCash, event, access);

      return access;
    },
    userInRole: (state) => (role) => {
      if (state.userRolesCash[role] !== undefined) {
        return state.userRolesCash[role];
      }

      if (ROLES[role] === undefined) {
        throw new Error(`Not found role: '${role}'`);
      }
      if (!state.userRolesIds.length) {
        return false;
      }

      const eventId = ROLES[role];

      const access = state.userRolesIds.includes(eventId);

      Vue.set(state.userRolesCash, role, access);

      return access;
    },
  },
}
