<template>
  <div class="tabs-ui">
    <div class="tabs-ui__tabs">
      <ul ref="tabsUiTitle"
          class="tabs-ui__nav">
      </ul>
      <slot name="afterTitles"/>
    </div>
    <div class="tabs-ui__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {randomNumber} from "@/services/utilsFunctions";

export default {
  name: "TabsUi",
  data() {
    return {
      id: randomNumber(1000, 9999)
    }
  },
  props: {
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      lazy: this.lazy
    }
  },
  updated() {
    this.updateTabs();
  },
  mounted() {
    this.updateTabs();
  },
  methods: {
    updateTabs() {
      const tabsUiTitle = this.$refs.tabsUiTitle;
      tabsUiTitle.innerHTML = '';
      this.$children
          .filter(child => child.$options.name === 'TabUi' && child?.$slots?.title)
          .forEach((child, index) => {
            child.$refs.tabUiTitle.id = `tab-${this.id}-${index}`;
            tabsUiTitle.appendChild(child.$refs.tabUiTitle);
          });
    },
    setActiveTab(index) {
      this.$nextTick(() => {
        console.log(document.getElementById(`tab-${this.id}-${index}`))
        document.getElementById(`tab-${this.id}-${index}`).click();
      })
    },
  },
};
</script>