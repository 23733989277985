<template>
    <div class="ratio-a4 d-inline-flex justify-content-center align-items-center w-100">
        <div class="text-center">
            <div class="spinner-alpha-container mb-3">
                <i class="spinner-alpha"></i>
            </div>
            <span class="text-muted">Загрузка данных</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FileLoadingPreview'
}
</script>