<template>
  <div class="form--file">
    <label v-if="label" v-html="label"/>
    <div class="form--file-wrapper"
         @dragover="dragOverFile = true"
         @dragenter="dragOverFile = true"
         @drag="dragOverFile = false"
         @dragstart="dragOverFile = false"
         @dragend="dragOverFile = false"
         @dragleave="dragOverFile = false"
         @drop="dragOverFile = false">
      <div v-if="isEmpty" class="form--file-empty">
        <div class="btn-upload"><upload-icon/>Загрузить файлы</div>
        <div>Выберите и загрузите файл с компьютера</div>
        <div>или перетащите в это поле</div>
      </div>
      <div v-else class="form--file-list">
        <div class="form--file-list__item" v-for="(file, key) in this.files" :key="file.base64">
          <div class="form--file-list__item-name">{{ file.name }}</div>
          <div class="form--file-list__item-icon">
            <exel-form-file-icon v-if="['xls', 'xlsm', 'xlsx', 'xltx', 'xlsb', 'xlam'].includes(getExtension(file.name))"/>
            <word-form-file-icon v-else-if="['doc', 'docx', 'docm'].includes(getExtension(file.name))"/>
            <pdf-form-file-icon v-else-if="['pdf'].includes(getExtension(file.name))"/>
            <default-form-file-icon v-else/>
          </div>
          <div class="form--file-list__item-footer">
            <div class="form--file-list__item-footer__size">{{ fileSize(file.size) }}</div>
            <div class="form--file-list__item-footer__remove">
              <div v-if="!disabled" class="form--file-list__item-footer__remove-icon" @click="remove(file)">
                <remove-form-file-icon/>
              </div>
            </div>
          </div>
          <div v-if="isLastFile(key)" class="form--file-list__item--add-icon">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9945 1H7.00573C3.69209 1 1.00586 3.68623 1.00586 6.99987V24.9995C1.00586 28.3131 3.69209 30.9993 7.00573 30.9993H15.9945H16.0055H24.9943C28.3079 30.9993 30.9941 28.3131 30.9941 24.9995V6.99987C30.9941 3.68623 28.3079 1 24.9943 1H16.0055H15.9945ZM16.0001 7.8332C15.1742 7.83615 14.5055 8.50657 14.5055 9.33316V14.4997H9.33899C8.51058 14.4997 7.83903 15.1713 7.83903 15.9997C7.83903 16.8281 8.51058 17.4996 9.33899 17.4996H14.5055V22.6662C14.5055 23.4928 15.1741 24.1632 16 24.1662C16.8259 24.1632 17.4945 23.4928 17.4945 22.6662V17.4996H22.661C23.4894 17.4996 24.161 16.8281 24.161 15.9997C24.161 15.1713 23.4894 14.4997 22.661 14.4997H17.4945V9.33315C17.4945 8.50661 16.8259 7.83622 16.0001 7.8332Z"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="form--file-wrapper__drop-file" v-if="dragOverFile">Бросайте файлы сюда</div>
      <input :disabled="disabled" type="file" ref="inputFile" multiple title="" @change="addFile()"/>
    </div>
    <span
        v-if="caption || error"
        class="form--input-caption"
        :class="error ? 'form--input-caption__error' : ''"
    >
			{{ error ? error : caption }}
		</span>
  </div>
</template>

<script>
import UploadIcon from "@/components/icons/UploadIcon";
import {randomKey} from "@/services/utilsFunctions";
import {fileSize} from "@/services/utilsFunctions";
import ExelFormFileIcon from "@/components/icons/files/form/ExelFormFileIcon";
import WordFormFileIcon from "@/components/icons/files/form/WordFormFileIcon";
import PdfFormFileIcon from "@/components/icons/files/form/PdfFormFileIcon";
import DefaultFormFileIcon from "@/components/icons/files/form/DefaultFormFileIcon";
import RemoveFormFileIcon from "@/components/icons/files/form/RemoveFormFileIcon";

export default {
  name: "FileForm",
  components: {RemoveFormFileIcon, DefaultFormFileIcon, PdfFormFileIcon, WordFormFileIcon, ExelFormFileIcon, UploadIcon},
  data() {
    return {
      files: [],
      dragOverFile: false,
      inputFileId: randomKey('input-file')
    }
  },
  props: {
    value: {},
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Сообщение об ошибке
     * @type {string}
     */
    error: {
      type: String,
      default: ''
    },
    /**
     * Текст для <label>
     * @type {string}
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Подсказка
     * @type {string}
     */
    caption: {
      type: String,
      default: ''
    },
  },
  created() {
    this.files = this.value || [];
  },
  computed: {
    isEmpty() {
      return this.files.length === 0;
    },
  },
  methods: {
    fileSize,
    isLastFile(key) {
      return key === this.files.length - 1 && !this.disabled;
    },
    getExtension(fileName) {
      return fileName.split('.').pop();
    },
    remove(file) {
      this.files = this.files.filter(f => f != file);

      this.$emit('emitValue', this.files);
    },
    async addFile() {
      if (this.disabled) {
        return;
      }
      const files = this.$refs.inputFile.files;

      for (const file of files) {
        const data = {
          name: file.name,
          size: file.size,
          base64: await this.toBase64(file),
        }

        if (!this.files.includes(data)) {
          this.files.push(data)
        }

        this.$emit('emitValue', this.files);
      }
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split('base64,')[1]);
        reader.onerror = error => reject(error);
      })
    },
  }
}
</script>