<template>
  <a
    class="file-truncated-name"
    :class="{_muted: isMuted, _disabled: disabled}"
    href="#"
    :aria-label="fileNameType"
    data-tooltip-position="top"
    data-tooltip-size="auto"
    :role="showTooltip && 'tooltip'"
    @mouseenter="setShowTooltip"
    @click.prevent="downloadFile"
  >
    <span ref="nameRef" class="title">{{ fileName }}</span>
    <span>.{{ fileType }}</span>
  </a>
</template>

<script>
import viewFile from '@/mixins/viewFile';
import downloadFile from '@/mixins/downloadFile';
import {defineComponent} from 'vue';

export default defineComponent({
  name: "FileTruncatedName",
  props: {
    file: {
      type: Object,
      required: true,
    },
    isMuted: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [viewFile, downloadFile],
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    setShowTooltip() {
      this.showTooltip = this.$refs.nameRef.scrollWidth > this.$refs.nameRef.clientWidth + 1;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors";

.file-truncated-name {
  min-width: 0;
  display: flex;

  letter-spacing: 0.01em;
  white-space: nowrap;

  &._disabled {
    pointer-events: none;

    &:not(._muted) {
      color: $gray-white-color;
    }
  }

  &._muted {
    color: $blue-light-color-2;
    font-size: 10px;
    line-height: 14px;
  }

  &::after {
    /* TODO: Избавиться от !important */
    width: 230px !important;
    word-wrap: break-word;
  }
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
