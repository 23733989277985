import { render, staticRenderFns } from "./DropdownUi.vue?vue&type=template&id=35719d65&scoped=true"
import script from "./DropdownUi.vue?vue&type=script&setup=true&lang=js"
export * from "./DropdownUi.vue?vue&type=script&setup=true&lang=js"
import style0 from "./DropdownUi.vue?vue&type=style&index=0&id=35719d65&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35719d65",
  null
  
)

export default component.exports