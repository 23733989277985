<template>
  <field-ui class="search-ui" :id="id" :label="label" :required="required" :error="errorMessage" :hint="hint">
    <dropdown-ui
      ref="dropdown"
      :offset="4"
      shrink
      full-width
      close-if-inner-click
      close-if-outside-click
      @open="$emit('focus')"
      @close="$emit('blur')"
    >
      <template #anchor>
        <input-ui
          ref="input"
          :id="id"
          :placeholder="placeholder"
          :color="color"
          :size="size"
          :disabled="disabled"
          :error="!!error"
          :no-clear="noClear"
          v-model.trim="input"
          @update:model-value="fetchOptions"
          @focus="onFocus"
          @blur="onBlur"
          @clear="onClear"
        >
          <template #postfix>
            <button-icon-ui @click="fetchOptions(input)">
              <search-icon></search-icon>
            </button-icon-ui>
          </template>
        </input-ui>
      </template>

      <div class="body">
        <div v-if="isLoading" class="state">
          <spinner-ui></spinner-ui>
        </div>

        <div v-else-if="!markedOptions.length" class="state">
          Ничего не найдено
        </div>

        <dropdown-list-ui v-else ref="list" :options="markedOptions" @select="onSelect"></dropdown-list-ui>
      </div>

      <collapse-button @click="$refs.dropdown.hide()"></collapse-button>
    </dropdown-ui>
  </field-ui>
</template>

<script>
import {defineComponent} from 'vue';
import SearchIcon from '@/assets/svg/search.svg?component';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import {debounce, uniqueId} from 'lodash-es';
import FieldUi from '@/components/ui/FieldUi.vue';
import InputUi from '@/components/ui/InputUi.vue';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import field from '@/mixins/form/field';
import {keys, only} from '@/utils/propsValidators';
import CollapseButton from '@/components/buttons/CollapseButton.vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import DropdownListUi from '@/components/ui/DropdownListUi.vue';

const SEARCH_DEBOUNCE = 500;

export default defineComponent({
  name: 'SearchUi',
  components: {
    DropdownListUi,
    SpinnerUi,
    CollapseButton,
    ButtonIconUi,
    InputUi,
    FieldUi,
    DropdownUi,
    SearchIcon,
  },
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
      validator: keys('label', 'code'),
    },
    color: {
      type: String,
      default: 'gray',
      validator: only('gray', 'white'),
    },
    size: {
      type: String,
      default: 'm',
      validator: only('m', 'l'),
    },
    getOptions: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Выберите значение',
    },
    noClear: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:model-value', 'focus', 'blur'],
  mixins: [field],
  data() {
    return {
      id: uniqueId('search-ui-'),
      input: '',
      options: [],
      isLoading: false,
    };
  },
  computed: {
    markedOptions() {
      return this.options.map(option => ({...option, checked: option.code === this.modelValue?.code}));
    },
  },
  watch: {
    modelValue(option) {
      this.input = option?.label || '';
    },
    input() {
      this.$refs.list?.setActive(null);
    },
  },
  methods: {
    onFocus() {
      if (this.isLoading || this.input) {
        this.$refs.dropdown.show();
      }
    },
    onBlur() {
      this.input = this.modelValue?.label || '';
    },
    onClear() {
      this.$emit('update:model-value', null);
    },
    onSelect(option) {
      this.input = option.label;
      this.$refs.dropdown.hide();
      this.$emit('update:model-value', option);
    },
    fetchOptions(query) {
      if (!query) {
        this.$refs.dropdown.hide();
        return;
      }

      this.isLoading = true;
      this.$refs.dropdown.show();
      void this.getOptionsDebounced(query);
    },
    getOptionsDebounced: debounce(async function (query) {
      this.options = await this.getOptions(query);
      this.isLoading = false;
    }, SEARCH_DEBOUNCE),
    focus() {
      this.$refs.input.focus();
    },
  },
});
</script>

<style scoped lang="scss">
.body {
  overflow: hidden;

  display: flex;
  flex-direction: column;

  box-shadow: var(--shadow);
  border-radius: 8px;
}

.state {
  min-height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse-button {
  flex-shrink: 0;
}
</style>
