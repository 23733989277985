<template>
  <modal-ui ref="modal" title="Добавление комментария" @shown="onShown" @hidden="onHidden">
    <template #body>
      <solution-autocorrect class="form-add-comment" @add-solution-autocorrect="addSolutionAutocorrect"/>

      <textarea-ui
        class="mt-5 mb-3"
        ref="textarea"
        v-model="comment"
        placeholder="Текст комментария"
        focus-on-mount
      ></textarea-ui>

      <file-drop-zone
          v-if="enabledFileDropZone"
          ref="fileDropZone"
          class="mt-5"
          @close="$emit('filesUploaded')"
          @hasErrorFilesUploaded="$emit('hasErrorFilesUploaded')"
          :doc_id="parentId"
      />
    </template>

    <template #footer>
      <close-button @click="hide"/>
      <add-button @click="apply"/>
    </template>
  </modal-ui>
</template>

<script>
import SolutionAutocorrect from "@/components/doc/solution/SolutionAutocorrect";
import {mapActions} from "vuex";
import CloseButton from "@/components/buttons/CloseButton";
import AddButton from "@/components/buttons/AddButton";
import FileDropZone from "@/components/doc/files/FileDropZone";
import VuexAdapter from "@/services/vuexAdapter";
import {CLIENTS_COMMENTS_GET, DOC_COMMENTS_GET, DOC_FILES_GET, SYSTEM_COMMENT_ADD} from "@/configs/endPoints";
import TextareaUi from "@/components/ui/TextareaUi.vue";
import {DOCS_TABLE} from "@/configs/dbTables";
import {ERROR_NOTIFY_TYPE} from "@/configs/notifyTypes";
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';

export default {
  name: "AddCommentModal",
  components: {ModalUi, TextareaUi, FileDropZone, AddButton, CloseButton, SolutionAutocorrect},
  mixins: [modal],
  props: {
    parentTableId: {
      type: Number,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    comment: '',
    has_error_files_uploaded: false,
  }),
  computed: {
    enabledFileDropZone() {
      return [DOCS_TABLE].includes(this.parentTableId);
    },
  },
  methods: {
    hasErrorFilesUploaded() {
      this.has_error_files_uploaded = true;

      this.$notify({
        title: 'Ошибка загрузки файла, продолжить?',
        type: ERROR_NOTIFY_TYPE,
      });

      this.$off('filesUploaded');
      this.hideLoader();
    },
    onShown() {
      this.$on('hasErrorFilesUploaded', this.hasErrorFilesUploaded);
    },
    onHidden() {
      this.$off('hasErrorFilesUploaded', this.hasErrorFilesUploaded);
      this.comment = null;
      this.has_error_files_uploaded = null;
    },
    addSolutionAutocorrect(text) {
      this.comment = this.comment + text + ' ';
      this.$refs.textarea.focus();
    },
    apply() {
      if (!this.comment?.length) {
        this.$notify({
          title: 'Введите комментарий',
          type: ERROR_NOTIFY_TYPE,
        });
        return;
      }

      this.showLoader();

      this.$on('filesUploaded', () => {
        this.$off('filesUploaded');

        this.addCommentsAction({
          'parent_table_id': this.parentTableId,
          'parent_id': this.parentId,
          'comment': this.comment,
        }).then(() => {
          this.refreshPartlyPreviewAction(CLIENTS_COMMENTS_GET);
          this.refreshPartlyPreviewAction(DOC_COMMENTS_GET);
          this.refreshPartlyPreviewAction(DOC_FILES_GET);
          this.addCommentAutocorrect(this.comment);
          this.hide();
        }).catch(e => {
          this.$notify({
            title: 'Ошибка добавления комментария',
            type: ERROR_NOTIFY_TYPE,
            text: e?.error_message || 'Неизвестная ошибка',
          });
          this.hideLoader();
        });
      });

      if (!this.enabledFileDropZone) {
        this.$emit('filesUploaded');

        return;
      }

      if (this.has_error_files_uploaded) {
        this.$emit('filesUploaded');
      } else {
        this.$refs.fileDropZone.upload();
      }
    },
    ...mapActions(['addCommentAutocorrect', 'refreshPartlyPreviewAction']),
    ...mapActions({
      addCommentsAction: VuexAdapter.getNameAction(SYSTEM_COMMENT_ADD),
    }),
  }
}
</script>
