<template>
  <div class="checkbox-tile-form">
    <div class="checkbox-tile-form__container"
         @click="select"
         :class="{
            active: proxyValue,
            'checkbox-tile-form__container__disabled': disabled
         }">
        <div class="checkbox-tile-form__label" v-html="label"/>
        <div class="checkbox-tile-form__icon" >
          <component v-if="icon" v-bind:is="icon"/>
        </div>
    </div>
    <span
        v-if="hint || error"
        class="form--input-caption"
        :class="error ? 'form--input-caption__error' : ''"
    >
			{{ error ? error : hint }}
		</span>
  </div>
</template>

<script>
import AttachFilesIcon from "@/components/icons/tile/AttachFilesIcon";
export default {
  name: "CheckboxTileForm",
  components: {AttachFilesIcon},
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    /**
     * Значение поля
     * @type {any}
     */
    modelValue: {},
    /**
     * Текст для <label>
     * @type {string}
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Компонент иконки <icon>
     * @type {string}
     */
    icon: {},
    /**
     * Аттрибут [disabled]
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Сообщение об ошибке.
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Подсказка.
     */
    hint: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    proxyValue: false,
  }),
  computed: {
    classes() {
      return {
        error: this.error,
        disabled: this.disabled,
      };
    },
  },
  created() {
    this.proxyValue = !!this.modelValue;
  },
  methods: {
    select() {
      if (this.disabled) {
        return;
      }
      this.proxyValue = !this.modelValue;

      this.change();
    },
    change() {
      this.$emit('change', this.proxyValue || false);
    },
  },
  watch: {
    modelValue(modelValue) {
      if (modelValue) {
        this.proxyValue = true;

        return;
      }

      this.proxyValue = false;
    },
  }
}
</script>