<template>
  <div class="additional-info-document-preview">
    <add-bank-details-modal ref="addModal" :parent-id="clientId" :parent-table-id="parentTableId"/>

    <preview-block-header
        title="Банковские реквизиты"
        :amount="amount"
        :show-add="showAdd"
        @toggle="toggle"
        @add="add"
    />

    <transition name="document-sheet">
      <div v-if="showContent" class="additional-info-document-preview--content">
        <uploader-block-client-preview
            class="mt-4"
            :end-point="endPoint"
            :client-id="clientId"
            :component="() => import('@/components/clients/rows/BankDetailsRow')"
        >
          <template v-slot:list-is-empty>Список банковских реквизитов пуст</template>
        </uploader-block-client-preview>
      </div>
    </transition>
  </div>
</template>

<script>
import UploaderBlockClientPreview from "@/components/clients/UploaderBlockClientPreview";
import PreviewBlockHeader from "@/components/doc/preview/PreviewBlockHeader.vue";
import {CLIENTS_BANK_DETAILS_GET} from "@/configs/endPoints";
import AddBankDetailsModal from "@/components/modals/BankDetails/AddBankDetailsModal.vue";
import clientsPreviewBlock from '@/mixins/clientsPreviewBlock';
import {CLIENTS_TABLE} from '@/configs/dbTables';

export default {
  name: "BankDetailsClientPreview",
  components: {AddBankDetailsModal, UploaderBlockClientPreview, PreviewBlockHeader},
  props: ['clientId'],
  mixins: [clientsPreviewBlock],
  computed: {
    parentTableId() {
      return CLIENTS_TABLE;
    },
    endPoint() {
      return CLIENTS_BANK_DETAILS_GET;
    }
  },
}
</script>
