<template>
  <modal-ui ref="modal" class="test" title="Обновление системы" :z-index="11_000" prevent-closure popup>
    <template #body>
      Обнаружено обновление системы, необходимо перезагрузить приложение.
    </template>

    <template #footer>
      <add-button @click="reloadVersionControlAction">
        <template #icon>
          <reload-icon/>
        </template>
        <template #title>Обновить</template>
      </add-button>
    </template>
  </modal-ui>
</template>

<script>
import AddButton from "@/components/buttons/AddButton";
import ReloadIcon from "@/components/icons/ReloadIcon";
import {mapActions} from "vuex";
import ModalUi from '@/components/ui/ModalUi.vue';
import modal from '@/mixins/modal';

export default {
  name: "VersionControlSystemModal",
  mixins: [modal],
  components: {ModalUi, ReloadIcon, AddButton},
  methods: {
    ...mapActions(['reloadVersionControlAction']),
  }
}
</script>
