<template>
  <div class="sign--table--row">
    <div class="sign--table--col--actions">
      <label v-if="file.base64" class="checkbox-container-custom" :for="checkboxId">
        <input type="checkbox" :id="checkboxId" v-model="file.active">
        <span class="checkmark"></span>
      </label>
      <div v-else class="spinner-alpha-container-sm ml-2">
        <i class="spinner-alpha spinner-alpha-sm"></i>
      </div>
    </div>

    <file-name-button class="sign--table--col--description" :file="file"/>

    <div class="sign--table--col--redaction">
      {{ file['Редакция'] }}
    </div>
    <div v-if="filesForSignHasRemarkGetter"
         class="sign--table--col--remark"
         :title="file['Примечание']">
      {{ file['Примечание'] }}
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import FileNameButton from '@/components/doc/files/FileNameButton.vue';

export default {
  name: "RowTableSign",
  components: {FileNameButton},
  props: {
    file: {
      required: true,
      type: Object,
    }
  },
  computed: {
    ...mapGetters(['filesForSignHasRemarkGetter']),
    checkboxId() {
      return 'select-sign-file-' + this.file.F_ID;
    },
  },
}
</script>
